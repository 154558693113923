import React from 'react';

import './Error404.scss';

export default class Error404 extends React.Component {
    render() {
        return (
            <div
                className={
                    'error-container container d-flex flex-column align-items-center justify-content-center'
                }
            >
                <h1 className={'mb-5'}>404 Error</h1>
                <h3 className={'mb-3'}>Oops! Page not found</h3>
                <p>Sorry, the page you're looking for doesn't exist.</p>
                <a href={`${window.location.origin}/`}>Return home</a>
            </div>
        );
    }
}
