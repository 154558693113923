import React from 'react';
import PropTypes from 'prop-types';
import PieChart from './PieChart';
import ApiService from '../services/ApiService';
import iconCyclists from '../assets/icons/chart2-cyclist.svg';
import iconWalkers from '../assets/icons/chart2-walker.svg';

import './DailyCount.scss';

export default class DailyCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: new Array(4).fill(0),
            dataTime: null,
            labels: ['Prichádzajúci cyklisti', 'Odchádzajúci cyklisti', 'Prichádzajúci chodci', 'Odchádzajúci chodci'],
            cyclists: null,
            walkers: null,
        };

        this.api = ApiService.getInstance();
    }

    async componentDidMount() {
        const response = await this.api.getDeviceDailyData(this.props.device.id, this.props.requestedDay);
        if (response.success) {
            this.updateData(response.data);
        } else {
            this.props.showAlert();
        }
    }

    async componentDidUpdate(prevProps) {
        // Device selection change or selected day change
        if (prevProps.device.id !== this.props.device.id || prevProps.requestedDay !== this.props.requestedDay) {
            const response = await this.api.getDeviceDailyData(this.props.device.id, this.props.requestedDay);

            if (response.success) {
                this.updateData(response.data);
            } else {
                this.props.showAlert();
            }
        }
    }

    updateData(data) {
        this.setState({
            cyclists: data.cyclists,
            walkers: data.walkers,
        });
    }

    render() {
        return (
            <div className="py-3 d-flex flex-row justify-content-start">
                <div className="pr-4 daily-count-wrapper">
                    <div className="min-inline mt-1 mb-3">
                        <img height={37} className="mr-2" src={iconCyclists} alt={'cyclists icon'}></img>Počet
                        cyklistov:{' '}
                        <h6 className="d-inline">{this.state.cyclists === null ? '-' : this.state.cyclists}</h6>
                    </div>
                    <div className="min-inline mb-1">
                        <img height={37} className="mr-2" src={iconWalkers} alt={'pedestrians icon'}></img>Počet
                        chodcov: <h6 className="d-inline">{this.state.walkers === null ? '-' : this.state.walkers}</h6>
                    </div>
                </div>
                <div className="pl-4">
                    <PieChart
                        device={this.props.device}
                        showAlert={this.props.showAlert}
                        requestedDay={this.props.requestedDay}
                    />
                </div>
            </div>
        );
    }
}

DailyCount.propTypes = {
    device: PropTypes.object,
    showAlert: PropTypes.func,
    requestedDay: PropTypes.string,
};
