import React from 'react';
import Sidebar from '../WebComponents/Sidebar';
import { PageType } from '../types';

import Chart1 from '../WebComponents/Chart1';
import Chart2 from '../WebComponents/Chart2';
import moment from 'moment';
import DataQuery from '../WebComponents/DataQuery';
import ApiService from '../services/ApiService';
import DailyCount from '../WebComponents/DailyCount';
import DatePicker from '../WebComponents/DatePicker';

import './Dashboard.scss';
import TabList from '../WebComponents/TabList';
import DailyCountCars from '../WebComponents/DailyCountCars';
import Chart1Cars from '../WebComponents/Chart1Cars';
import Chart2Cars from '../WebComponents/Chart2Cars';

import Highlight from 'react-highlight';
import 'highlight.js/styles/vs2015.css';

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingDevices: true,
            availableDevices: [],
            selectedPage: null, // PageType enum
            selectedDevice: null, // Can be null if not DEVICE PageType selected, object reference to availableDevices
            selectedTab: 0, // Selected tab for cars or cyclists + walkers
            selectedDay: moment.utc().startOf('day'),
            deviceDailyData: null,
            selectedApiTab: 0, // 0 - API docs, 1 - Change log
        };

        this.modalRef = React.createRef();

        this.api = ApiService.getInstance();

        this.handleSidebarPage = this.handleSidebarPage.bind(this);
        this.handleDatetimeChange = this.handleDatetimeChange.bind(this);
    }

    async componentDidMount() {
        // Load available devices
        const devicesResponse = await this.api.getDevicesInfo();
        console.log(devicesResponse);

        if (!devicesResponse) {
            this.setState(
                {
                    loadingDevices: false,
                },
                () => {
                    this.props.showAlert();
                },
            );
        } else {
            const devices = devicesResponse.data;

            // Convert each createdAt string to moment object as firstDataTime
            // time of createdAt refers to first data time
            for (const device of devices) {
                device.firstDataTime = moment(device.createdAt);
                delete device.createdAt;
            }

            switch (this.props.initialState) {
                case PageType.DEVICE:
                    if (devices.length === 0) {
                        return;
                    }
                    const currentId = parseInt(window.location.pathname.split('/').pop());

                    const deviceIndex = devices.findIndex((item) => item.id === currentId);

                    console.log(deviceIndex);

                    if (deviceIndex === -1) {
                        // Invalid id
                        this.setState({
                            availableDevices: devices,
                            loadingDevices: false,
                            selectedDevice: devices[0], // Select first available device
                            selectedPage: PageType.DEVICE,
                        });
                    } else {
                        this.setState({
                            availableDevices: devices,
                            loadingDevices: false,
                            selectedDevice: devices[deviceIndex], // Select first available device
                            selectedPage: PageType.DEVICE,
                        });
                    }
                    break;
                case PageType.API:
                    this.setState({
                        availableDevices: devices,
                        loadingDevices: false,
                        selectedPage: PageType.API,
                    });
                    break;
                case PageType.DATA_QUERY:
                    this.setState({
                        availableDevices: devices,
                        loadingDevices: false,
                        selectedPage: PageType.DATA_QUERY,
                    });
                    break;
                default:
                    if (devices.length === 0) {
                        return;
                    }

                    this.setState({
                        availableDevices: devices,
                        loadingDevices: false,
                        selectedDevice: devices[0], // Select first available device
                        selectedPage: PageType.DEVICE,
                    });
                    break;
            }
        }
    }

    handleSidebarPage(pageType, device = null) {
        if (pageType === PageType.DEVICE) {
            this.setState({
                selectedPage: pageType,
                selectedDevice: device,
            });
        } else {
            this.setState({
                selectedPage: pageType,
            });
        }

        switch (pageType) {
            case PageType.DEVICE:
                this.props.history.replace(`/device/${device.id}`);
                break;
            case PageType.API:
                this.props.history.replace('/api');
                break;
            case PageType.DATA_QUERY:
                this.props.history.replace('/data-query');
                break;
            default:
                break;
        }
    }

    getMeasurementTypeText(type) {
        switch (type) {
            case 'BOTH':
                return 'Obojsmerné';
            case 'IN':
            case 'OUT':
                return 'Jednosmerné';
            default:
                return '';
        }
    }

    handleDatetimeChange(datetime) {
        this.setState({
            selectedDay: moment.utc(datetime).startOf('day'),
        });
    }

    renderSelection() {
        if (this.state.selectedPage != null) {
            switch (this.state.selectedPage) {
                case PageType.DEVICE:
                    return this.renderDevicePage();
                case PageType.ABOUT:
                    return this.renderAboutUs();
                case PageType.API:
                    return this.renderApiPage();
                case PageType.DATA_QUERY:
                    return (
                        <>
                            <DataQuery devices={this.state.availableDevices} showAlert={this.props.showAlert} />
                        </>
                    );
                default:
                    <p>Invalid page</p>;
            }
        } else {
            // null PageType refers to default dashboard home
            if (this.state.loadingDevices) {
                return this.renderLoadingPage();
            } else {
                return this.renderNoDevices();
            }
        }
    }

    renderApiPage() {
        return (
            <TabList
                autoResizeTabContentHeight={false}
                selectedTab={this.state.selectedApiTab}
                onActiveTabChanged={(index) => {
                    this.setState({
                        selectedApiTab: index,
                    });
                }}
                tabs={[
                    {
                        name: 'Dokumentácia',
                        content: (
                            <div className={'widget-container px-4 py-4 mb-3'}>
                                <h4 className={'mt-1 pb-3'}>API</h4>
                                <div>
                                    <p>
                                        Public API is available at:{' '}
                                        <a href={'https://api.mybikecounter.com'}>{`https://api.mybikecounter.com`}</a>
                                    </p>
                                    <p>
                                        Each request requires{' '}
                                        <code className={'ms-1 me-1 inline-code'}>Authorization</code> header field
                                        filled with user's API key.
                                    </p>
                                    <p>
                                        Other parameters have to be included in request body in{' '}
                                        <code className={'ms-1 me-1 inline-code'}>x-www-form-urlencoded</code> form.
                                    </p>
                                    <div class="alert alert-warning" role="alert">
                                        Old API endpoints:
                                        <code className={'ms-1 me-1 inline-code'}>POST /api/get_all_devices</code>,
                                        <code className={'ms-1 me-1 inline-code'}>POST /api/device_data</code>,
                                        <code className={'ms-1 me-1 inline-code'}>POST /api/device_dustiness</code> are
                                        now deprecated and will not be available since April 12, 2023.
                                    </div>
                                    <hr />
                                    <h4>Get all devices</h4>
                                    <Highlight className={'html'}>POST /api/v1/get_all_devices</Highlight>
                                    <p>Returns information about user's devices including their IDs.</p>
                                    <div>
                                        <h6>cURL code example</h6>
                                        <Highlight className="bash">
                                            {
                                                "curl --location --request POST 'https://api.mybikecounter.com/api/v1/get_all_devices' \\\n\
     --header 'Authorization: <API_KEY>'"
                                            }
                                        </Highlight>
                                    </div>
                                    <div>
                                        <h6>Example response:</h6>
                                        <Highlight className="json">
                                            {
                                                '{\n\t"devices":[{\n\t\t"id": 1,\n\t\t"name": "Example API device",\n\t\t"latitude": "48.0",\n\t\t"longitude": "17.0",\n\t\t"directionIn": "Long avenue",\n\t\t"directionOut": "Short avenue",\n\t\t"hasDustinessSensor": true,\n\t\t"createdAt": "2023-01-01T10:00:00.000Z"\n\t}]\n}'
                                            }
                                        </Highlight>
                                    </div>
                                    <hr />
                                    <h4>Get device data</h4>
                                    <div className="d-flex flex-row">
                                        <p>
                                            Returns data for specified <code className="inline-code">id</code>
                                        </p>
                                    </div>
                                    <Highlight className={'html'}>POST /api/v1/device_data</Highlight>
                                    <p>Required parameters:</p>
                                    <ul>
                                        <li>
                                            <code className="inline-code">device</code> - device ID
                                        </li>
                                        <li>
                                            <code className="inline-code">timeFrom</code> - requested data time start in
                                            ISO 8601 time format
                                        </li>
                                        <li>
                                            <code className="inline-code">timeTo</code> - requested data time end in ISO
                                            8601 time format
                                        </li>
                                    </ul>
                                    <div>
                                        <h6>Example request parameters:</h6>
                                        <Highlight>
                                            {
                                                'device: 1\ntimeFrom: 2023-02-27T00:00:00+0100\ntimeTo: 2023-02-27T23:00:00.000Z'
                                            }
                                        </Highlight>
                                    </div>
                                    <p>Returns data from single device in hour interval.</p>
                                    <div>
                                        <h6>cURL code example</h6>
                                        <Highlight className="bash">
                                            {
                                                "curl --location 'https://api.mybikecounter.com/api/v1/device_data' \\\n\
     --header 'Authorization: <API_KEY>' \\\n\
     --header 'Content-Type: application/x-www-form-urlencoded' \\\n\
     --data-urlencode 'device=4102' \\\n\
     --data-urlencode 'timeFrom=2023-02-27T00:00:00+0100' \\\n\
     --data-urlencode 'timeTo=2023-02-27T23:00:00.000Z'"
                                            }
                                        </Highlight>
                                    </div>
                                    <div>
                                        <h6>Example response:</h6>
                                        <Highlight className={'json'}>
                                            {
                                                '[{\n\t"deviceId": 1,\n\t"createdAt": "2023-02-27T23:00:00.000Z",\n\t"cyclistsIn": 20,\n\t"cyclistsOut": 42,\n\t"walkersIn": 12,\n\t"walkersOut": 6,\n\t"carsIn": 18,\n\t"carsOut": 7,\n\t"trucksIn": 4,\n\t"trucksOut": 2\n}]'
                                            }
                                        </Highlight>
                                    </div>
                                    <hr />
                                    <h4>Get device dustiness data</h4>
                                    <p>Returns device's dustiness data.</p>
                                    <Highlight className={'html'}>POST /api/v1/device_dustiness</Highlight>
                                    <div class="alert alert-secondary" role="alert">
                                        <p className="m-0">
                                            This request is available only for devices that returned{' '}
                                            <code className="inline-code">hasDustinessSensor</code> with true value in{' '}
                                            <code className="inline-code">get_all_devices</code> request.
                                        </p>
                                    </div>
                                    <p>Required parameters:</p>
                                    <ul>
                                        <li>
                                            <code className={'ms-1 me-1 inline-code'}>device</code> - device ID
                                        </li>
                                        <li>
                                            <code className={'ms-1 me-1 inline-code'}>timeFrom</code> - requested data
                                            time start in UNIX timestamp
                                        </li>
                                        <li>
                                            <code className={'ms-1 me-1 inline-code'}>timeTo</code> - requested data
                                            time end in UNIX timestamp
                                        </li>
                                    </ul>
                                    <div>
                                        <h6>Example request parameters:</h6>
                                        <Highlight>
                                            {
                                                'device: 1\ntimeFrom: 2023-02-27T00:00:00+0100\ntimeTo: 2023-02-27T23:00:00.000Z'
                                            }
                                        </Highlight>
                                    </div>
                                    <div>
                                        <h6>cURL code example</h6>
                                        <Highlight className={'bash'}>
                                            {"curl --location 'https://api.mybikecounter.com/api/v1/device_dustiness' \\\n\
     --header 'Authorization: <API_KEY>' \\\n\
     --header 'Content-Type: application/x-www-form-urlencoded' \\\n\
     --data-urlencode 'device=4102' \\\n\
     --data-urlencode 'timeFrom=2023-02-27T00:00:00+0100' \\\n\
     --data-urlencode 'timeTo=2023-02-27T23:00:00.000Z'"}
                                        </Highlight>
                                    </div>
                                    <div>
                                        <h6>Example response:</h6>
                                        <Highlight className={'json'}>
                                            {
                                                '[{\n\t"deviceId": 1,\n\t"createdAt": "2023-02-27T22:59:49.939Z",\n\t"dustiness": "2"\n}]'
                                            }
                                        </Highlight>
                                    </div>
                                </div>
                            </div>
                        ),
                    },
                    {
                        name: 'Zoznam zmien',
                        content: (
                            <div>
                                <h6>2023-02-28</h6>
                                <ul>
                                    <li>
                                        Updated API to <bold>version 1</bold>
                                    </li>
                                    <ul>
                                        <li>
                                            <code className={'ms-1 me-1 inline-code'}>POST /api/v1/device_data</code>:
                                            updated <code className={'ms-1 me-1 inline-code'}>timeFrom</code> and{' '}
                                            <code className={'ms-1 me-1 inline-code'}>timeTo</code> to ISO 8601 time
                                            format
                                        </li>
                                        <li>
                                            <code className={'ms-1 me-1 inline-code'}>
                                                POST /api/v1/device_dustiness
                                            </code>
                                            : updated <code className={'ms-1 me-1 inline-code'}>timeFrom</code> and{' '}
                                            <code className={'ms-1 me-1 inline-code'}>timeTo</code> to ISO 8601 time
                                            format
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                        ),
                    },
                ]}
            ></TabList>
        );
    }

    renderLoadingPage() {
        return <p>Načítavam...</p>;
    }

    renderNoDevices() {
        return <p>Niesú dostupné žiadne zariadenia.</p>;
    }

    renderDeviceDirectionText() {
        let measurementType = '';

        if (this.state.selectedDevice.dataType === 'CARS') {
            if (this.state.selectedTab === 0) {
                measurementType = this.getMeasurementTypeText(this.state.selectedDevice.cyclistsTypeDirection);
            } else {
                measurementType = this.getMeasurementTypeText(this.state.selectedDevice.carsTypeDirection);
            }
        } else {
            measurementType = this.getMeasurementTypeText(this.state.selectedDevice.cyclistsTypeDirection);
        }

        if (
            (this.state.selectedDevice.dataType === 'CARS' &&
                ((this.state.selectedTab === 0 && this.state.selectedDevice.cyclistsTypeDirection === 'BOTH') ||
                    (this.state.selectedTab === 1 && this.state.selectedDevice.carsTypeDirection === 'BOTH'))) ||
            (this.state.selectedDevice.dataType === 'CYCLISTS_WALKERS' &&
                this.state.selectedDevice.cyclistsTypeDirection === 'BOTH')
        ) {
            return (
                <div className="h-100 d-flex flex-column align-items-baseline device-direction-block">
                    <div className="pt-2 d-flex flex-row justify-content-start">
                        <div className={'pr-3 device-subtitle d-flex flex-row align-items-baseline'}>
                            <p className="mb-0">Typ merania:</p>
                            <h6 className="ml-1 mb-0">{measurementType}</h6>
                        </div>

                        <div className={'px-3 device-subtitle d-flex flex-row align-items-baseline'}>
                            <p className="mb-0">Smer prichádzajúcich:</p>
                            <h6 className="ml-1 mb-0">{this.state.selectedDevice.directionIn}</h6>
                        </div>

                        <div className={'pl-3 d-flex flex-row align-items-baseline'}>
                            <p className="mb-0">Smer odchádzajúcich:</p>
                            <h6 className="ml-1 mb-0">{this.state.selectedDevice.directionOut}</h6>
                        </div>
                    </div>
                </div>
            );
        } else if (
            (this.state.selectedDevice.dataType === 'CARS' &&
                ((this.state.selectedTab === 0 && this.state.selectedDevice.cyclistsTypeDirection === 'IN') ||
                    (this.state.selectedTab === 1 && this.state.selectedDevice.carsTypeDirection === 'IN'))) ||
            (this.state.selectedDevice.dataType === 'CYCLISTS_WALKERS' &&
                this.state.selectedDevice.cyclistsTypeDirection === 'IN')
        ) {
            return (
                <div className="h-100 d-flex flex-column align-items-baseline device-direction-block">
                    <div className="pt-2 d-flex flex-row justify-content-start">
                        <div className={'pr-3 device-subtitle d-flex flex-row align-items-baseline'}>
                            <p className="mb-0">Typ merania:</p>
                            <h6 className="ml-1 mb-0">{measurementType}</h6>
                        </div>

                        <div className={'pl-3 d-flex flex-row align-items-baseline'}>
                            <p className="mb-0">Smer:</p>
                            <h6 className="ml-1 mb-0">{this.state.selectedDevice.directionIn}</h6>
                        </div>
                    </div>
                </div>
            );
        } else if (
            (this.state.selectedDevice.dataType === 'CARS' &&
                ((this.state.selectedTab === 0 && this.state.selectedDevice.cyclistsTypeDirection === 'OUT') ||
                    (this.state.selectedTab === 1 && this.state.selectedDevice.carsTypeDirection === 'OUT'))) ||
            (this.state.selectedDevice.dataType === 'CYCLISTS_WALKERS' &&
                this.state.selectedDevice.cyclistsTypeDirection === 'OUT')
        ) {
            return (
                <div className="h-100 d-flex flex-column align-items-baseline device-direction-block">
                    <div className="pt-2 d-flex flex-row justify-content-start">
                        <div className={'pr-3 device-subtitle d-flex flex-row align-items-baseline'}>
                            <p className="mb-0">Typ merania:</p>
                            <h6 className="ml-1 mb-0">{measurementType}</h6>
                        </div>

                        <div className={'pl-3 d-flex flex-row align-items-baseline'}>
                            <p className="mb-0">Smer:</p>
                            <h6 className="ml-1 mb-0">{this.state.selectedDevice.directionOut}</h6>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderDevicePage() {
        if (this.state.selectedDevice.dataType === 'CARS') {
            return (
                <>
                    <div className="widget-container px-4 py-4 mb-3">
                        <h4 className="mt-1 mb-3 pr-3">{`${
                            this.state.selectedDevice.name
                        }, aktívne od ${this.state.selectedDevice.firstDataTime.format('DD.MM.YYYY, HH:mm')}`}</h4>
                        {this.renderDeviceDirectionText()}
                    </div>
                    <TabList
                        autoResizeTabContentHeight={false}
                        tabs={[
                            {
                                name: 'Cyklisti a chodci',
                                content: (
                                    <>
                                        <div className="widget-container px-4 py-4 mb-3">
                                            <div className={'d-flex flex-row pb-3'}>
                                                <h4 className={'mt-1 pr-3'}>Návštevníkov počas dňa: </h4>
                                                <DatePicker
                                                    selectedDate={this.state.selectedDay.toDate()}
                                                    handleChange={this.handleDatetimeChange}
                                                />
                                            </div>
                                            <DailyCount
                                                device={this.state.selectedDevice}
                                                showAlert={this.props.showAlert}
                                                requestedDay={this.state.selectedDay.toISOString()}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-xl-12">
                                                <Chart1
                                                    device={this.state.selectedDevice}
                                                    showAlert={this.props.showAlert}
                                                    requestedDay={this.state.selectedDay}
                                                />
                                            </div>
                                            <div className="col-lg-12 col-xl-12">
                                                <Chart2
                                                    device={this.state.selectedDevice}
                                                    showAlert={this.props.showAlert}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ),
                            },
                            {
                                name: 'Vozidlá',
                                content: (
                                    <>
                                        <div className="widget-container px-4 py-4 mb-3">
                                            <div className={'d-flex flex-row pb-3'}>
                                                <h4 className={'mt-1 pr-3'}>Návštevníkov počas dňa: </h4>
                                                <DatePicker
                                                    selectedDate={this.state.selectedDay.toDate()}
                                                    handleChange={this.handleDatetimeChange}
                                                />
                                            </div>
                                            <DailyCountCars
                                                device={this.state.selectedDevice}
                                                showAlert={this.props.showAlert}
                                                requestedDay={this.state.selectedDay.toISOString()}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-xl-12">
                                                <Chart1Cars
                                                    device={this.state.selectedDevice}
                                                    showAlert={this.props.showAlert}
                                                    requestedDay={this.state.selectedDay}
                                                />
                                            </div>
                                            <div className="col-lg-12 col-xl-12">
                                                <Chart2Cars
                                                    device={this.state.selectedDevice}
                                                    showAlert={this.props.showAlert}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ),
                            },
                        ]}
                        selectedTab={this.state.selectedTab}
                        onActiveTabChanged={(index) => {
                            this.setState({
                                selectedTab: index,
                            });
                        }}
                    />
                </>
            );
        } else if (this.state.selectedDevice.dataType === 'CYCLISTS_WALKERS') {
            return (
                <>
                    <div className="widget-container px-4 py-4 mb-3">
                        <h4 className="mt-1 mb-3 pr-3">{`${
                            this.state.selectedDevice.name
                        }, aktívne od ${this.state.selectedDevice.firstDataTime.format('DD.MM.YYYY, HH:mm')}`}</h4>
                        {this.renderDeviceDirectionText()}
                    </div>
                    <div className="widget-container px-4 py-4 mb-3">
                        <div className={'d-flex flex-row pb-3'}>
                            <h4 className={'mt-1 pr-3'}>Návštevníkov počas dňa: </h4>
                            <DatePicker
                                selectedDate={this.state.selectedDay.toDate()}
                                handleChange={this.handleDatetimeChange}
                            />
                        </div>
                        <DailyCount
                            device={this.state.selectedDevice}
                            showAlert={this.props.showAlert}
                            requestedDay={this.state.selectedDay.toISOString()}
                        />
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-xl-12">
                            <Chart1
                                device={this.state.selectedDevice}
                                showAlert={this.props.showAlert}
                                requestedDay={this.state.selectedDay}
                            />
                        </div>
                        <div className="col-lg-12 col-xl-12">
                            <Chart2 device={this.state.selectedDevice} showAlert={this.props.showAlert} />
                        </div>
                    </div>
                </>
            );
        }
    }

    render() {
        return (
            <div className={'root-container'}>
                <Sidebar
                    selectedPage={this.state.selectedPage}
                    selectedDevice={this.state.selectedDevice}
                    devices={this.state.availableDevices}
                    handleChange={this.handleSidebarPage}
                />
                <div className={'content px-4 pt-3'}>{this.renderSelection()}</div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    initialState: Number,
};
