import React from 'react';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import ApiService from '../services/ApiService';

import './Chart1.scss';
import Switch from './Switch';

const LABELS_24H = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
];

const COLOR_CARS_IN = '#9f1853'; // darker
const COLOR_CARS_OUT = '#ff7eb6'; // lighter
const COLOR_TRUCKS_IN = '#198038';
const COLOR_TRUCKS_OUT = '#6fdc8c';
// const COLOR_BUSES_IN = '#6929c4';
// const COLOR_BUSES_OUT = '#8a3ffc';
// const COLOR_MOTOCYCLES_IN = '#009d9a';
// const COLOR_MOTOCYCLES_OUT = '#08bdba';

const LABEL_CARS_IN = 'Prichádzajúce osobné vozidlá';
const LABEL_CARS_OUT = 'Odchádzajúce osobné vozidlá';
const LABEL_TRUCKS_IN = 'Prichádzajúce ostatné vozidlá';
const LABEL_TRUCKS_OUT = 'Odchádzajúce ostatné vozidlá';
// const LABEL_BUSES_IN = 'Prichádzajúce autobusy';
// const LABEL_BUSES_OUT = 'Odchádzajúce autobusy';
// const LABEL_MOTORCYCLES_IN = 'Prichádzajúce motorky';
// const LABEL_MOTORCYCLES_OUT = 'Odchádzajúce motorky';

const LABEL_CARS_SINGLE = 'Osobné vozidlá';
const LABEL_TRUCKS_SINGLE = 'Ostatné vozidlá';
// const LABEL_BUSES_SINGLE = 'Autobusy';
// const LABEL_MOTORCYCLES_SINGLE = 'Motorky';

const WEATHER_ICON_MAX_WIDTH = 48;
const WEATHER_ICON_Y_BOTTOM_BASE = 12;
const WEATHER_TEXT_BOTTOM_BASE = 8;

export default class Chart1Cars extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            labels: LABELS_24H,
            showWeather: true,
        };

        this.weatherData = {
            temperature: new Array(24).fill(null),
            fellTemperature: new Array(24).fill(null),
            windSpeed: new Array(24).fill(null),
            rain: new Array(24).fill(null),
            snow: new Array(24).fill(null),
            weatherIcons: new Array(24).fill(null),
        };
        this.weatherIconElements = {};

        this.api = ApiService.getInstance();

        this.handleWeatherEnableSwitch = this.handleWeatherEnableSwitch.bind(this);
    }

    async componentDidMount() {
        let datasets;

        if (this.props.device.carsTypeDirection === 'BOTH') {
            datasets = [
                {
                    label: LABEL_CARS_IN,
                    data: new Array(24).fill(0),
                    backgroundColor: COLOR_CARS_IN,
                    borderSkipped: true,
                    stack: 'Stack 0',
                },
                {
                    label: LABEL_CARS_OUT,
                    data: new Array(24).fill(0),
                    backgroundColor: COLOR_CARS_OUT,
                    borderSkipped: true,
                    stack: 'Stack 0',
                },
                {
                    label: LABEL_TRUCKS_IN,
                    data: new Array(24).fill(0),
                    backgroundColor: COLOR_TRUCKS_IN,
                    borderSkipped: true,
                    stack: 'Stack 0',
                },
                {
                    label: LABEL_TRUCKS_OUT,
                    data: new Array(24).fill(0),
                    backgroundColor: COLOR_TRUCKS_OUT,
                    borderSkipped: true,
                    stack: 'Stack 0',
                },
            ];
        } else {
            datasets = [
                {
                    label: LABEL_CARS_SINGLE,
                    data: new Array(24).fill(0),
                    backgroundColor: COLOR_CARS_IN,
                    borderSkipped: true,
                    stack: 'Stack 0',
                },
                {
                    label: LABEL_TRUCKS_SINGLE,
                    data: new Array(24).fill(0),
                    backgroundColor: COLOR_TRUCKS_IN,
                    borderSkipped: true,
                    stack: 'Stack 0',
                },
            ];
        }

        this.chartHtml = document.getElementById('data-chart');
        Chart.defaults.global.defaultFontColor = '#95AAC9';
        Chart.defaults.global.defaultFontFamily = "'Ubuntu', sans-serif";
        this.chart = new Chart(this.chartHtml, {
            type: 'bar',
            data: {
                labels: this.state.labels,
                datasets: datasets,
            },
            options: {
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                    yAxes: [
                        {
                            gridLines: {
                                borderDash: [4],
                                color: 'rgba(149, 170, 201, 0.33)',
                            },
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 8,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                responsive: true,
                legend: {
                    display: true,
                },
                cornerRadius: 20,
                tooltips: {
                    callbacks: {
                        footer: (tooltipItems) => {
                            return (
                                (this.weatherData.temperature[tooltipItems[0].index] !== null
                                    ? 'Teplota: ' + this.weatherData.temperature[tooltipItems[0].index] + ' °C\n'
                                    : '') +
                                (this.weatherData.fellTemperature[tooltipItems[0].index] !== null
                                    ? 'Pocitová teplota: ' +
                                      this.weatherData.fellTemperature[tooltipItems[0].index] +
                                      ' °C\n'
                                    : '') +
                                (this.weatherData.windSpeed[tooltipItems[0].index] !== null
                                    ? 'Rýchlosť vetra: ' + this.weatherData.windSpeed[tooltipItems[0].index] + ' m/s\n'
                                    : '') +
                                (this.weatherData.rain[tooltipItems[0].index] !== null
                                    ? 'Zrážky: ' + this.weatherData.rain[tooltipItems[0].index] + ' mm/h\n'
                                    : '') +
                                (this.weatherData.snow[tooltipItems[0].index] !== null
                                    ? 'Sneženie: ' + this.weatherData.snow[tooltipItems[0].index] + ' mm/h\n'
                                    : '')
                            );
                        },
                    },
                },
            },
            plugins: [
                {
                    afterInit: function (chart) {
                        chart.legend.afterFit = function () {
                            let offset = chart.width > 2000 ? 94 : 66;
                            this.height = this.height + offset;
                        };
                    },
                    afterDraw: (chart) => {
                        if (this.state.showWeather) {
                            const ctx = chart.ctx;
                            ctx.font = Chart.helpers.fontString(
                                Chart.defaults.global.defaultFontSize,
                                Chart.defaults.global.defaultFontStyle,
                                Chart.defaults.global.defaultFontFamily,
                            );

                            // Setting, to draw text and images behind
                            ctx.globalCompositeOperation = 'destination-over';

                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';

                            // Find most top dataset
                            let mostTopDatasetIndex = -1;
                            for (let i = 0; i < chart.legend.legendItems.length; i++) {
                                if (!chart.legend.legendItems[i].hidden) {
                                    mostTopDatasetIndex = i;
                                }
                            }

                            if (mostTopDatasetIndex !== -1) {
                                const meta = chart.getDatasetMeta(mostTopDatasetIndex);

                                let image = null;
                                let imageRatio = 0;
                                let imageWidth = 0;
                                let imageHeight = 0;

                                for (let i = 0; i < meta.data.length; i++) {
                                    if (this.weatherData.weatherIcons[i] !== null) {
                                        image = this.weatherIconElements[this.weatherData.weatherIcons[i]];
                                        imageRatio = image.height / image.width;
                                        imageWidth =
                                            meta.data[i]._model.width > WEATHER_ICON_MAX_WIDTH
                                                ? WEATHER_ICON_MAX_WIDTH
                                                : meta.data[i]._model.width;
                                        imageHeight = imageRatio * imageWidth;

                                        ctx.drawImage(
                                            image,
                                            meta.data[i]._model.x - imageWidth / 2,
                                            meta.data[i]._model.y - WEATHER_ICON_Y_BOTTOM_BASE - imageHeight,
                                            imageWidth,
                                            imageHeight,
                                        );
                                    }

                                    if (this.weatherData.temperature[i] !== null) {
                                        ctx.fillText(
                                            `${this.weatherData.temperature[i]}°`,
                                            meta.data[i]._model.x,
                                            meta.data[i]._model.y -
                                                WEATHER_ICON_Y_BOTTOM_BASE -
                                                imageHeight -
                                                WEATHER_TEXT_BOTTOM_BASE,
                                        );
                                    }
                                }
                            }

                            // Return setting back, to draw elements on the top
                            ctx.globalCompositeOperation = 'source-over';
                        }
                    },
                },
            ],
        });

        const response = await this.api.getChart1CarsData(this.props.device.id, this.props.requestedDay);

        if (response.success) {
            this.getWeatherIconImages();
            this.updateChartData(response.data);
        } else {
            this.props.showAlert();
        }
    }

    async componentDidUpdate(prevProps) {
        // Device selection change
        if (prevProps.device.id !== this.props.device.id || prevProps.requestedDay !== this.props.requestedDay) {
            const response = await this.api.getChart1CarsData(this.props.device.id, this.props.requestedDay);

            if (response.success) {
                this.updateChartData(response.data);
            } else {
                this.props.showAlert();
            }
        }
    }

    getWeatherIconImages() {
        const weatherCodes = ['01d', '01n', '02d', '02n', '03', '04', '09', '10d', '10n', '11d', '11n', '13', '50'];

        for (let code of weatherCodes) {
            this.weatherIconElements[code] = document.getElementById(`weather-ico-${code}`);
        }
    }

    updateChartData(data) {
        if (this.chart.data.datasets && this.props.device) {
            if (this.props.device.carsTypeDirection === 'BOTH') {
                this.chart.data.datasets[0].data = data.carsIn;
                this.chart.data.datasets[1].data = data.carsOut;
                this.chart.data.datasets[2].data = data.trucksIn;
                this.chart.data.datasets[3].data = data.trucksOut;
            } else if (this.props.device.carsTypeDirection === 'IN' || this.props.device.carsTypeDirection === 'OUT') {
                this.chart.data.datasets[0].data = data.carsIn.map((elem, i) => elem + data.carsOut[i]);
                this.chart.data.datasets[1].data = data.trucksIn.map((elem, i) => elem + data.trucksOut[i]);
            }
            this.weatherData.temperature = data.temperature;
            this.weatherData.fellTemperature = data.fellTemperature;
            this.weatherData.windSpeed = data.windSpeed;
            this.weatherData.rain = data.rain;
            this.weatherData.snow = data.snow;
            this.weatherData.weatherIcons = data.weatherIcons;
        }
        this.chart.update();
    }

    handleWeatherEnableSwitch(event) {
        this.setState(
            {
                showWeather: event.target.checked,
            },
            () => {
                this.chart.update();
            },
        );
    }

    render() {
        return (
            <div className={'widget-container px-4 py-4 mb-3'}>
                <Switch
                    text={'Zobraziť počasie'}
                    checked={this.state.showWeather}
                    handleChange={this.handleWeatherEnableSwitch}
                />
                <canvas id="data-chart" width={500} height={150} />
                <div className="weather-icons-container">
                    <img id="weather-ico-01d" alt="01d" src={`${window.location.origin}/assets/weather/png/01d.png`} />
                    <img id="weather-ico-01n" alt="01n" src={`${window.location.origin}/assets/weather/png/01n.png`} />
                    <img id="weather-ico-02d" alt="02d" src={`${window.location.origin}/assets/weather/png/02d.png`} />
                    <img id="weather-ico-02n" alt="02n" src={`${window.location.origin}/assets/weather/png/02n.png`} />
                    <img id="weather-ico-03" alt="03" src={`${window.location.origin}/assets/weather/png/03.png`} />
                    <img id="weather-ico-04" alt="04" src={`${window.location.origin}/assets/weather/png/04.png`} />
                    <img id="weather-ico-09" alt="09" src={`${window.location.origin}/assets/weather/png/09.png`} />
                    <img id="weather-ico-10d" alt="10d" src={`${window.location.origin}/assets/weather/png/10d.png`} />
                    <img id="weather-ico-10n" alt="10n" src={`${window.location.origin}/assets/weather/png/10n.png`} />
                    <img id="weather-ico-11d" alt="11d" src={`${window.location.origin}/assets/weather/png/11d.png`} />
                    <img id="weather-ico-11n" alt="11n" src={`${window.location.origin}/assets/weather/png/11n.png`} />
                    <img id="weather-ico-13" alt="13" src={`${window.location.origin}/assets/weather/png/13.png`} />
                    <img id="weather-ico-50" alt="50" src={`${window.location.origin}/assets/weather/png/50.png`} />
                </div>
            </div>
        );
    }
}

Chart1Cars.propTypes = {
    device: PropTypes.object,
    showAlert: PropTypes.func,
    requestedDay: PropTypes.object,
};
