import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/sk';
import sk from 'date-fns/locale/sk';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

export default class DatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.datePickerRef = React.createRef();

        registerLocale('sk', sk);
        moment.locale('sk');

        // Get local date format
        // Need to replace D -> d, Y - y because react-datepicker is using date-fns which uses other format
        // Look up more: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
        this.dateTimeFormat = moment().localeData().longDateFormat('LL').replaceAll('D', 'd').replaceAll('Y', 'y');

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // Turn off spellcheck on react-datepicker input container
        this.datePickerRef.current.input.spellcheck = false;
    }

    handleChange(date) {
        this.props.handleChange(date);
    }

    render() {
        return (
            <div className={this.props.className}>
                <ReactDatePicker
                    ref={this.datePickerRef}
                    selected={this.props.selectedDate}
                    onChange={this.handleChange}
                    dateFormat={this.dateTimeFormat}
                    locale="sk"
                    calendarStartDay={1}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    minDate={this.props.minDate}
                    selectsStart={this.props.selectsStart}
                    selectsEnd={this.props.selectsEnd}
                    placeholderText={this.props.placeholder}
                />
            </div>
        );
    }
}

DatePicker.propTypes = {
    className: PropTypes.string,
    handleChange: PropTypes.func,
    selectedDate: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    minDate: PropTypes.object,
    selectsStart: PropTypes.bool,
    selectsEnd: PropTypes.bool,
    placeholder: PropTypes.string,
};
