/**
 * @author Tomáš Hladký
 */

 import React from "react";
 import * as PropTypes from "prop-types";
 
 import "./Switch.scss";
 
 export default class Switch extends React.Component {
     constructor(props) {
         super(props);
 
         this.handleChange = this.handleChange.bind(this);
     }
 
     handleChange(event) {
         this.props.handleChange(event);
     }
 
     render() {
         return (
             <div className={this.props.className}>
                 <div className="switch">
                     <input type="checkbox" id="switch1" className="switch__input" onChange={this.handleChange} checked={this.props.checked}/>
                         <label htmlFor="switch1" className="switch__label">{this.props.text}</label>
                 </div>
             </div>
         );
     }
 
 }
 
 Switch.propTypes = {
     className: PropTypes.string,
 
     checked: PropTypes.bool,
     handleChange: PropTypes.func,
 
     text: PropTypes.string
 };