import React from 'react';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import ApiService from '../services/ApiService';

import './PieChart.scss';

export default class PieChartCars extends React.Component {
    constructor(props) {
        super(props);

        this.api = ApiService.getInstance();
    }

    async componentDidMount() {
        let labels;
        let initData;
        let colors;

        if (this.props.device.carsTypeDirection === 'BOTH') {
            labels = [
                'Prich. osobné vozidlá',
                'Odch. osobné vozidlá',
                'Prich. ostatné vozidlá',
                'Odch. ostatné vozidlá',
            ];
            initData = new Array(4).fill(0);
            colors = ['#9f1853', '#ff7eb6', '#198038', '#6fdc8c'];
        } else {
            labels = ['Osobné vozidlá', 'Ostatné vozidlá'];
            initData = new Array(2).fill(0);
            colors = ['#9f1853', '#198038'];
        }

        this.chartHtml = document.getElementById('pie-chart');
        Chart.defaults.global.defaultFontColor = '#95AAC9';
        Chart.defaults.global.defaultFontFamily = "'Ubuntu', sans-serif";
        this.chart = new Chart(this.chartHtml, {
            type: 'pie',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Počet áut',
                        data: initData,
                        backgroundColor: colors,
                    },
                ],
            },
            options: {
                responsive: true,
                legend: {
                    position: 'right',
                },
            },
        });

        const response = await this.api.getPieChartDataCars(this.props.device.id, this.props.requestedDay);
        if (response.success) {
            this.updateChartData(response.data);
        } else {
            this.props.showAlert();
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.device.id !== this.props.device.id || prevProps.requestedDay !== this.props.requestedDay) {
            const response = await this.api.getPieChartDataCars(this.props.device.id, this.props.requestedDay);

            if (response.success) {
                this.updateChartData(response.data);
            } else {
                this.props.showAlert();
            }
        }
    }

    updateChartData(data) {
        if (this.chart.data.datasets && this.props.device) {
            if (this.props.device.carsTypeDirection === 'BOTH') {
                this.chart.data.datasets[0].data[0] = data.carsIn;
                this.chart.data.datasets[0].data[1] = data.carsOut;
                this.chart.data.datasets[0].data[2] = data.trucksIn;
                this.chart.data.datasets[0].data[3] = data.trucksOut;
            } else if (this.props.device.carsTypeDirection === 'IN' || this.props.device.carsTypeDirection === 'OUT') {
                this.chart.data.datasets[0].data[0] = data.carsIn + data.carsOut;
                this.chart.data.datasets[0].data[1] = data.trucksIn + data.trucksOut;
            }
        }
        this.chart.update();
    }

    render() {
        return (
            <>
                <canvas id="pie-chart" width={300} />
            </>
        );
    }
}

PieChartCars.propTypes = {
    device: PropTypes.object,
    showAlert: PropTypes.func,
    requestedDay: PropTypes.string,
};
