import React from 'react';
import PropTypes from 'prop-types';
import { PageType } from '../types';
import iconPin from '../assets/icons/map-pin.svg';
import iconDatabase from '../assets/icons/database.svg';
import iconInfo from '../assets/icons/info.svg';
import iconLogo from '../assets/icons/logo.svg';

import './Sidebar.scss';
import ApiService from '../services/ApiService';

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.api = ApiService.getInstance();
        
        this.logout = this.logout.bind(this);
    }

    logout() {
        this.api.logout();
    }

    renderDevices() {
        return this.props.devices.map((device) => (
            <div
                className={
                    'category-item px-4 py-3' +
                    (this.props.selectedPage === PageType.DEVICE && this.props.selectedDevice === device
                        ? ' selected'
                        : '')
                }
                key={device.id}
                onClick={() => this.props.handleChange(PageType.DEVICE, device)}
            >
                <img src={iconPin} className={'icon pr-3'} alt={'Device map pin icon'} />
                <h6 className={'my-2'}>{device.name}</h6>
            </div>
        ));
    }

    renderInfo() {
        return (
            <>
                {/* <div
                    className={
                        'category-item px-4 py-3' + (this.props.selectedPage === PageType.ABOUT ? ' selected' : '')
                    }
                    onClick={() => this.props.handleChange(PageType.ABOUT)}
                >
                    <img src={iconInfo} className={'icon pr-3'} alt={'Info icon'} />
                    <h6 className={'my-2'}>O Solar Counter</h6>
                </div> */}
                <div
                    className={
                        'category-item px-4 py-3' + (this.props.selectedPage === PageType.API ? ' selected' : '')
                    }
                    onClick={() => this.props.handleChange(PageType.API)}
                >
                    <img src={iconInfo} className={'icon pr-3'} alt={'Info icon'} />
                    <h6 className={'my-2'}>API</h6>
                </div>
            </>
        );
    }

    render() {
        return (
            <div className={'sidebar-container pb-3'}>
                <div className={'title mt-3 px-3'}>
                    <img src={iconLogo} className={'icon pr-3'} alt={'Logo icon'} />
                    <div className={'pr-3'}>
                        <h4 className={'title-text pr-3'}>Solar Counter</h4>
                        <p className={'user-text pr-3'}>{this.api.getUsername()}</p>
                    </div>
                </div>
                <p className={'sim-nav-link nav-link logout-text'} onClick={this.logout}>
                    Odhlásiť sa
                </p>
                <hr />
                <div className={'category-text mt-3 px-3'}>
                    <h5 className={'title-text'}>Dostupné zariadenia</h5>
                </div>
                {this.renderDevices()}

                <hr />
                <div className={'category-text mt-3 px-3'}>
                    <h5 className={'title-text'}>Export dát</h5>
                </div>
                <div
                    className={
                        'category-item px-4 py-3' + (this.props.selectedPage === PageType.DATA_QUERY ? ' selected' : '')
                    }
                    onClick={() => this.props.handleChange(PageType.DATA_QUERY)}
                >
                    <img src={iconDatabase} className={'icon data-query-icon pr-3'} alt={'Database icon'} />
                    <h6 className={'my-2'}>Export dát</h6>
                </div>

                <hr />
                <div className={'category-text mt-3 px-3'}>
                    <h5 className={'title-text'}>Informácie</h5>
                </div>
                {this.renderInfo()}
            </div>
        );
    }
}

Sidebar.propTypes = {
    selectedPage: PropTypes.number,
    selectedDevice: PropTypes.object,
    devices: PropTypes.array,
    handleChange: PropTypes.func,
};
