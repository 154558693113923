import { Moment } from 'moment';

// Types used in Private API
export type PrivDeviceInfo = {
    id: number;
    name: string;
    createdAt?: string;
    firstDataTime: Moment;
};

export type ResponsePrivDeviceInfo = {
    success: boolean;
    errorMessage?: string;
    devices?: PrivDeviceInfo[];
};

export type ResponsePrivChart1Data = {
    success: boolean;
    errorMessage?: string;
    data?: number[];
};

export type ResponsePrivPieData = {
    success: boolean;
    errorMessage?: string;
    data?: {
        cyclists: number;
        walkers: number;
    };
};

export type ResponsePrivChart2Data = {
    success: boolean;
    errorMessage?: string;
    data?: {
        cyclists: number[];
        walkers: number[];
    };
};

export type DeviceData = {
    deviceId: number;
    numOfCyclists: number;
    numOfWalkers: number;
    createdAt: Date;
};

export type QueryApiPreviewResponse = {
    success: boolean;
    errorMessage?: string;
    data: DeviceData[];
    page?: number;
    count?: number;
};

export enum PageType {
    DEVICE,
    DATA_QUERY,
    ABOUT,
    API,
}

export enum Chart2Range {
    r24h,
    r7d,
    r1m,
    r3m,
    r6m,
    r1y,
    r2y,
    rAll,
}

export enum Chart2Type {
    IN = 'in',
    OUT = 'out',
    TOTAL = 'total',
}

export enum OrderDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export enum DataQueryPageControl {
    FIRST,
    PREV,
    CURR,
    NEXT,
    LAST,
}
