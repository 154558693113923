import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimelineNavigator from './TimelineNavigator';
import ApiService from '../services/ApiService';
import { Chart2Range, Chart2Type } from '../types';
import iconCyclists from '../assets/icons/chart2-cyclist.svg'
import iconWalkers from '../assets/icons/chart2-walker.svg';

import './Chart2.scss';

export default class Chart2 extends React.Component {
    constructor(props) {
        super(props);

        // ======= Chart configuartion =======
        this.VISITORS_CLASSES = ['cyclists', 'walkers'];
        this.VISITORS_ICONS = [iconCyclists, iconWalkers];
        this.VISITORS_COLORS_FILL = ['rgba(86, 132, 248, 0.16)', 'rgba(255, 189, 51, 0.16)'];
        this.VISITORS_COLORS_BORDER = ['#5684F8', '#FFBD33'];
        this.MISSING_DATA_TEXT = '-';

        this.MIN_SEGMENTS = 2;
        this.MAX_SEGMENTS = 9;

        this.state = {
            timeRangeSelected: Chart2Range.r24h,
            typeSelected: Chart2Type.IN,
            fromTime: moment().startOf('hour').subtract(24, 'hours'),
            toTime: moment().startOf('hour'),
            labelsScale: 'scale-3',
            segments: 4,
            segmentWidth: 0,
            data: {
                cyclists: [30, 25, 42, 18],
                walkers: [12, 16, 11, 19],
            },
            times: ['16:00:00', '17:00:00', '18:00:00', '19:00:00', '20:00:00'],
            dataLoading: false,
        };

        this.tableRef = React.createRef();
        this.timelineNavRef = React.createRef();
        this.tableWidth = 0;

        this.api = ApiService.getInstance();

        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.device.id !== this.props.device.id) {
            this.handleTimeRangeSelection(this.state.timeRangeSelected, true);
        }
    }

    handleTimeChange(momentFrom, momentTo, segments, segmentSize) {
        let newTimes = [];

        // console.log(`${momentFrom.format('HH:mm:ss')} - ${momentTo.format('HH:mm:ss')}`);

        let timeFormatString;
        let labelsScale;
        const daysDiff = momentTo.diff(momentFrom, 'days');

        if (daysDiff > 180) {
            // style class scale-1
            timeFormatString = 'DD.MM.YYYY[\n]     HH:mm';
            labelsScale = 'scale-1';
        } else if (daysDiff > 2 && daysDiff <= 180) {
            // style class scale-2
            timeFormatString = 'DD.MM.[\n]HH:mm';
            labelsScale = 'scale-2';
        } else {
            // style class scale-3
            timeFormatString = 'DD.MM.[\n]HH:mm';
            labelsScale = 'scale-3';
        }

        newTimes.push(momentFrom.format(timeFormatString));
        for (let i = 0; i < segments; i++) {
            newTimes.push(momentFrom.add(segmentSize, 'hour').format(timeFormatString));
        }

        const emptyData = Array(segments).fill(-1);

        if (this.tableWidth === 0) {
            // 54 is width of row icon, 40 margin for labels
            this.tableWidth = this.tableRef.current.getBoundingClientRect().width - 54 - 40;
        }

        const segmentWidth = this.tableWidth / segments;
        const newData = { cyclists: emptyData, walkers: emptyData };

        this.setState({
            dataLoading: true,
            data: newData,
            segments: segments,
            segmentWidth: segmentWidth,
            times: newTimes,
            labelsScale: labelsScale,
        });
    }

    async handleTimeUpdate(momentFrom, momentTo, segments) {
        // console.log(`%cTime from: ${momentFrom.toDate().toLocaleString()}`, 'background: #222; color: #FFBD33');
        // console.log(`%cTime to: ${momentTo.toDate().toLocaleString()}`, 'background: #222; color: #FFBD33');

        const response = await this.api.getChart2Data(this.props.device.id, momentFrom, momentTo, segments, this.state.typeSelected);

        if (response.success) {
            this.setState({
                dataLoading: false,
                data: response.data,
            });
        } else {
            this.setState(
                {
                    dataLoading: false,
                },
                () => {
                    this.props.showAlert();
                },
            );
        }
    }

    handleTimeRangeSelection(selection, refreshOnly = false) {
        if (selection !== this.state.timeRangeSelected || refreshOnly) {
            let fromTime;

            switch (selection) {
                case Chart2Range.r24h:
                    fromTime = moment().startOf('hour').subtract(24, 'hours');
                    break;
                case Chart2Range.r7d:
                    fromTime = moment().startOf('hour').subtract(7, 'days');
                    break;
                case Chart2Range.r1m:
                    fromTime = moment().startOf('hour').subtract(1, 'month');
                    break;
                case Chart2Range.r3m:
                    fromTime = moment().startOf('hour').subtract(3, 'months');
                    break;
                case Chart2Range.r6m:
                    fromTime = moment().startOf('hour').subtract(6, 'months');
                    break;
                case Chart2Range.r1y:
                    fromTime = moment().startOf('hour').subtract(1, 'year');
                    break;
                case Chart2Range.r2y:
                    fromTime = moment().startOf('hour').subtract(2, 'year');
                    break;
                case Chart2Range.rAll:
                    fromTime = this.props.device.firstDataTime.clone();
                    break;
                default:
                    fromTime = moment().startOf('hour').subtract(7, 'days');
                    break;
            }

            const toTime = moment().startOf('hour');

            this.setState({
                fromTime: fromTime,
                toTime: toTime,
                timeRangeSelected: selection,
            }, () => {
                if (refreshOnly) {
                    // console.log('handle update');
                    this.timelineNavRef.current.sendUpdate();
                }
            });
        }
    }

    handleTypeSelection(selection, refreshOnly = true) {
        if (selection !== this.state.typeSelected || refreshOnly) {
            this.setState({
                typeSelected: selection
            }, () => {
                if (refreshOnly) {
                    this.timelineNavRef.current.sendUpdate();
                }
            });
        }
    }

    renderContent() {
        return (
            <table className={'chart2-container'} ref={this.tableRef}>
                <tbody>
                    {this.VISITORS_CLASSES.map((visitorClass, index) => (
                        <tr key={visitorClass}>
                            <td className={'td-row-icon py-3'}>
                                <img
                                    className={'mr-3'}
                                    src={this.VISITORS_ICONS[index]}
                                    alt={`${visitorClass} type icon`}
                                />
                            </td>
                            {this.state.data[visitorClass].map((segmentData, innerIndex) => (
                                <td
                                    key={`${index}_${innerIndex}`}
                                    className={'td-segment py-3'}
                                    style={{ width: `${this.state.segmentWidth}px` }}
                                >
                                    <div
                                        className={'segment'}
                                        style={{
                                            backgroundColor: this.VISITORS_COLORS_FILL[index],
                                            color: this.VISITORS_COLORS_BORDER[index],
                                            borderColor: this.VISITORS_COLORS_BORDER[index],
                                        }}
                                    >
                                        {this.state.dataLoading
                                            ? '...'
                                            : segmentData === -1
                                            ? this.MISSING_DATA_TEXT
                                            : segmentData}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        {this.state.times.map((time, index) => (
                            <td key={index} className={'td-time'}>
                                <div className={'time-text ' + this.state.labelsScale}>{time}</div>
                            </td>
                        ))}
                    </tr>
                </tfoot>
            </table>
        );
    }

    render() {
        return (
            <div className={'widget-container px-4 py-4 mb-3'}>
                <div className={'chart2-title-container pb-3'}>
                    <h4 className={'mt-1 pr-3'}>Návštevníkov počas obdobia: </h4>
                    <div>
                        <div className={'time-range-selection'}>
                            <div
                                className={
                                    'ui-button mr-2' +
                                    (this.state.timeRangeSelected === Chart2Range.r24h ? ' selected' : '')
                                }
                                onClick={() => this.handleTimeRangeSelection(Chart2Range.r24h)}
                            >
                                24h
                            </div>
                            <div
                                className={
                                    'ui-button mx-2' +
                                    (this.state.timeRangeSelected === Chart2Range.r7d ? ' selected' : '')
                                }
                                onClick={() => this.handleTimeRangeSelection(Chart2Range.r7d)}
                            >
                                7d
                            </div>
                            <div
                                className={
                                    'ui-button mx-2' +
                                    (this.state.timeRangeSelected === Chart2Range.r1m ? ' selected' : '')
                                }
                                onClick={() => this.handleTimeRangeSelection(Chart2Range.r1m)}
                            >
                                1m
                            </div>
                            <div
                                className={
                                    'ui-button mx-2' +
                                    (this.state.timeRangeSelected === Chart2Range.r3m ? ' selected' : '')
                                }
                                onClick={() => this.handleTimeRangeSelection(Chart2Range.r3m)}
                            >
                                3m
                            </div>
                            <div
                                className={
                                    'ui-button mx-2' +
                                    (this.state.timeRangeSelected === Chart2Range.r6m ? ' selected' : '')
                                }
                                onClick={() => this.handleTimeRangeSelection(Chart2Range.r6m)}
                            >
                                6m
                            </div>
                            <div
                                className={
                                    'ui-button mx-2' +
                                    (this.state.timeRangeSelected === Chart2Range.r1y ? ' selected' : '')
                                }
                                onClick={() => this.handleTimeRangeSelection(Chart2Range.r1y)}
                            >
                                1r
                            </div>
                            <div
                                className={
                                    'ui-button mx-2' +
                                    (this.state.timeRangeSelected === Chart2Range.r2y ? ' selected' : '')
                                }
                                onClick={() => this.handleTimeRangeSelection(Chart2Range.r2y)}
                            >
                                2r
                            </div>
                            <div
                                className={
                                    'ui-button type-select-button ml-2' +
                                    (this.state.timeRangeSelected === Chart2Range.rAll ? ' selected' : '')
                                }
                                onClick={() => this.handleTimeRangeSelection(Chart2Range.rAll)}
                            >
                                Celé obdobie
                            </div>
                        </div>
                        <div className="type-selection">
                            <div
                                className={
                                    'ui-button type-select-button mx-2' + (this.state.typeSelected === Chart2Type.IN ? ' selected' : '')
                                }
                                onClick={() => this.handleTypeSelection(Chart2Type.IN)}
                            >
                                Prichádzajúci
                            </div>
                            <div
                                className={
                                    'ui-button type-select-button mx-2' + (this.state.typeSelected === Chart2Type.OUT ? ' selected' : '')
                                }
                                onClick={() => this.handleTypeSelection(Chart2Type.OUT)}
                            >
                                Odchádzajúci
                            </div>
                            <div
                                className={
                                    'ui-button type-select-button ml-2' + (this.state.typeSelected === Chart2Type.TOTAL ? ' selected' : '')
                                }
                                onClick={() => this.handleTypeSelection(Chart2Type.TOTAL)}
                            >
                                Všetci
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderContent()}
                <TimelineNavigator
                    className={'pt-4 mt-3 mb-3'}
                    ref={this.timelineNavRef}
                    device={this.props.device}
                    width={900}
                    fromUnix={this.state.fromTime.unix()}
                    toUnix={this.state.toTime.unix()}
                    minSegments={this.MIN_SEGMENTS}
                    maxSegments={this.MAX_SEGMENTS}
                    handleChange={this.handleTimeChange}
                    handleUpdate={this.handleTimeUpdate}
                />
            </div>
        );
    }
}

Chart2.propTypes = {
    device: PropTypes.object,
    showAlert: PropTypes.func,
};
