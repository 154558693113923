import React from 'react';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import ApiService from '../services/ApiService';

import './PieChart.scss';

const LABELS_BOTH = ['Prichádzajúci cyklisti', 'Odchádzajúci cyklisti', 'Prichádzajúci chodci', 'Odchádzajúci chodci'];
const COLORS_BOTH = ['#272BF7', '#5684F8', '#F77213', '#FFBD33'];

const LABELS_ONE_DIRECTION = ['Cyklisti', 'Chodci'];
const COLORS_ONE_DIRECTION = ['#5684F8', '#FFBD33']

export default class PieChart extends React.Component {
    constructor(props) {
        super(props);

        this.api = ApiService.getInstance();
    }

    hideWalkers() {
        if (this.props.device.cyclistsTypeDirection === 'BOTH' && this.chart.getDatasetMeta(0).data.length > 1) {
            this.chart.getDatasetMeta(0).data[2].hidden = true;
            this.chart.getDatasetMeta(0).data[3].hidden = true;
            this.chart.update();
        }
    }

    showWalkers() {
        if (this.props.device.cyclistsTypeDirection === 'BOTH' && this.chart.getDatasetMeta(0).data.length > 1) {
            this.chart.getDatasetMeta(0).data[2].hidden = false;
            this.chart.getDatasetMeta(0).data[3].hidden = false;
            this.chart.update();
        }
    }

    async componentDidMount() {
        let labels;
        let initData;
        let colors;

        if (this.props.device.cyclistsTypeDirection === 'BOTH') {
            labels = LABELS_BOTH;
            initData = new Array(4).fill(0);
            colors = COLORS_BOTH;
        } else {
            labels = LABELS_ONE_DIRECTION;
            initData = new Array(2).fill(0);
            colors = COLORS_ONE_DIRECTION;
        }

        this.chartHtml = document.getElementById('pie-chart');
        Chart.defaults.global.defaultFontColor = '#95AAC9';
        Chart.defaults.global.defaultFontFamily = "'Ubuntu', sans-serif";
        this.chart = new Chart(this.chartHtml, {
            type: 'pie',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Počet ľudí',
                        data: initData,
                        backgroundColor: colors,
                    },
                ],
            },
            options: {
                responsive: true,
                legend: {
                    position: 'right',
                },
            },
        });

        const response = await this.api.getPieChartData(this.props.device.id, this.props.requestedDay);
        if (response.success) {
            this.updateChartData(response.data);
        } else {
            this.props.showAlert();
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.device.id !== this.props.device.id || prevProps.requestedDay !== this.props.requestedDay) {
            const response = await this.api.getPieChartData(this.props.device.id, this.props.requestedDay);

            if (response.success) {
                this.updateChartData(response.data);
            } else {
                this.props.showAlert();
            }
        }
    }

    updateChartData(data) {
        if (this.chart.data.datasets && this.props.device) {
            if (this.props.device.cyclistsTypeDirection === 'BOTH') {
                this.chart.data.labels = LABELS_BOTH;
                this.chart.data.datasets = [
                    {
                        label: 'Počet ľudí',
                        data: new Array(4).fill(0),
                        backgroundColor: COLORS_BOTH,
                    },
                ];

                this.chart.data.datasets[0].data[0] = data.cyclistsIn;
                this.chart.data.datasets[0].data[1] = data.cyclistsOut;
                this.chart.data.datasets[0].data[2] = data.walkersIn;
                this.chart.data.datasets[0].data[3] = data.walkersOut;
            } else if (this.props.device.cyclistsTypeDirection === 'IN' || this.props.device.cyclistsTypeDirection === 'OUT') {
                this.chart.data.labels = LABELS_ONE_DIRECTION;
                this.chart.data.datasets = [
                    {
                        label: 'Počet ľudí',
                        data: new Array(2).fill(0),
                        backgroundColor: COLORS_ONE_DIRECTION,
                    },
                ];

                this.chart.data.datasets[0].data[0] = data.cyclistsIn + data.cyclistsOut;
                this.chart.data.datasets[0].data[1] = data.walkersIn + data.walkersOut;
            }

            if (data.cyclistsIn <= 0 && data.cyclistsOut <= 0) {
                this.showWalkers();
            } else {
                this.hideWalkers();
            }
        }
        this.chart.update();
    }

    render() {
        return <canvas id="pie-chart" width={300} />;
    }
}

PieChart.propTypes = {
    device: PropTypes.object,
    showAlert: PropTypes.func,
    requestedDay: PropTypes.string,
};
