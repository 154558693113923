import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {API_PORT, API_URL} from './env'

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

// Init env (cannot load in browser JS)
global.API_URL = API_URL;
global.API_PORT = API_PORT;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
