import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { components } from 'react-select';

import './DropdownCheckbox.scss';
import Checkbox from './Checkbox';

const style = {
    control: (provided) => ({
        ...provided,
        borderColor: '#dde2ec',
        ':hover': {
            ...provided[':hover'],
            borderColor: '#c9ced7',
        },
    }),

    menu: (provided) => ({
        ...provided,
        zIndex: 2,
    }),
};

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <div className={'dropdown-checkbox-option'}>
                    <Checkbox
                        className={'pr-2 mt-auto mb-auto'}
                        checked={props.isSelected}
                        disabledChange={true}
                        handleChange={() => null}
                    />{' '}
                    <label className={'dropdown-checkbox-label'}>{props.label}</label>
                </div>
            </components.Option>
        </div>
    );
};

const allOption = {
    label: 'Všetky',
    value: '*',
};

const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue();
    let toBeRendered = children;
    if (currentValues.some((val) => val.value === allOption.value)) {
        toBeRendered = [[children[0][0]], children[1]];
    }

    return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>;
};

const MultiValue = (props) => {
    let labelToBeDisplayed;
    if (props.data.value === allOption.value) {
        labelToBeDisplayed = 'Všetky zariadenia';
    } else {
        labelToBeDisplayed = `${props.data.label}`;
    }
    return (
        <components.MultiValue {...props}>
            <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
    );
};

export default class DropdownCheckbox extends React.Component {
    render() {
        if (this.props.allowSelectAll) {
            return (
                <Select
                    {...this.props}
                    components={{
                        Option,
                        MultiValue,
                        ValueContainer,
                    }}
                    className={'select-container'}
                    classNamePrefix={'dropdown-checkbox'}
                    styles={style}
                    options={[this.props.allOption, ...this.props.options]}
                    onChange={(selected, event) => {
                        if (selected !== null && selected.length > 0) {
                            if (selected[selected.length - 1].value === this.props.allOption.value) {
                                return this.props.onChange([this.props.allOption, ...this.props.options]);
                            }
                            let result = [];
                            if (selected.length === this.props.options.length) {
                                if (selected.includes(this.props.allOption)) {
                                    result = selected.filter((option) => option.value !== this.props.allOption.value);
                                } else if (event.action === 'select-option') {
                                    result = [this.props.allOption, ...this.props.options];
                                }
                                return this.props.onChange(result);
                            }
                        }

                        return this.props.onChange(selected);
                    }}
                />
            );
        } else {
            return <Select {...this.props} />;
        }
    }
}

DropdownCheckbox.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
};

DropdownCheckbox.defaultProps = {
    allOption: {
        label: 'Všetky',
        value: '*',
    },
};
