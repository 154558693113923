import React from 'react';
import PropTypes from 'prop-types';

import './Checkbox.scss';

export default class Checkbox extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        if (this.props.handleChange) {
            this.props.handleChange(event);
        }
    }

    getClassName() {
        if (this.props.checked) {
            return 'checkbox checked';
        } else {
            return 'checkbox';
        }
    }

    getStyle() {
        if (typeof this.props.color !== 'undefined' && this.props.color !== 'none' && this.props.checked) {
            return { fill: this.props.color };
        } else {
            return null;
        }
    }

    renderCheckbox() {
        if (this.props.checked) {
            return this.renderCheckedPath();
        } else {
            return this.renderNonCheckedPath();
        }
    }

    renderNonCheckedPath() {
        return (
            <path
                d="M13.5,2C14.9,2,16,3.1,16,4.5v9c0,1.4-1.1,2.5-2.5,2.5h-9C3.1,16,2,14.9,2,13.5v-9C2,3.1,3.1,2,4.5,2H13.5 M13.5,0h-9
         C2,0,0,2,0,4.5v9C0,16,2,18,4.5,18h9c2.5,0,4.5-2,4.5-4.5v-9C18,2,16,0,13.5,0L13.5,0z"
            />
        );
    }

    renderCheckedPath() {
        return <path d="M13.5,18h-9C2,18,0,16,0,13.5v-9C0,2,2,0,4.5,0h9C16,0,18,2,18,4.5v9C18,16,16,18,13.5,18z" />;
    }

    render() {
        return (
            <div
                className={this.props.className}
                onChange={this.props.disabledChange ? (event) => event.preventDefault() : null}
                onClick={this.props.disabledChange ? (event) => event.preventDefault() : null}
            >
                <label className={'checkbox-label'}>
                    <input
                        onChange={this.handleChange}
                        checked={this.props.checked}
                        type={'checkbox'}
                        className={'checkbox-input'}
                    />
                    <img
                        className={'checkicon' + (this.props.checked ? '' : ' hidden')}
                        src={'assets/icons/check-icon.svg'}
                        alt={'Check icon'}
                    />
                    <svg className={this.getClassName()} style={this.getStyle()} width={18} height={18}>
                        {this.renderCheckbox()}
                    </svg>
                    <p className={'checkbox-text'}>{this.props.text}</p>
                </label>
            </div>
        );
    }
}

Checkbox.propTypes = {
    className: PropTypes.string,

    checked: PropTypes.bool,
    handleChange: PropTypes.func,

    color: PropTypes.string,
    text: PropTypes.string,

    disabledChange: PropTypes.bool,
};

Checkbox.defaultProps = {
    color: 'none',
};
