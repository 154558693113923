import React from 'react';
import PropTypes from 'prop-types';
import ApiService from '../services/ApiService';
import PieChartCars from './PieChartCars';
import iconCars from '../assets/icons/chart2-cars.svg';
import iconTrucks from '../assets/icons/chart2-trucks.svg';

import './DailyCount.scss';

export default class DailyCountCars extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: new Array(8).fill(0),
            dataTime: null,
            labels: [
                'Prichádzajúce osobné vozidlá',
                'Odchádzajúce osobné vozidlá',
                'Prichádzajúce ostatné vozidlá',
                'Odchádzajúce ostatné vozidlá',
                'Prichádzajúce autobusy',
                'Odchádzajúce autobusy',
                'Prichádzajúce motorky',
                'Odchádzajúce motorky',
            ],
            cars: null,
            trucks: null,
            motorcycles: null,
            buses: null,
        };

        this.api = ApiService.getInstance();
    }

    async componentDidMount() {
        const response = await this.api.getDeviceDailyCarsData(this.props.device.id, this.props.requestedDay);
        if (response.success) {
            this.updateData(response.data);
        } else {
            this.props.showAlert();
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.device.id !== this.props.device.id || prevProps.requestedDay !== this.props.requestedDay) {
            const response = await this.api.getDeviceDailyCarsData(this.props.device.id, this.props.requestedDay);

            if (response.success) {
                this.updateData(response.data);
            } else {
                this.props.showAlert();
            }
        }
    }

    updateData(data) {
        this.setState({
            cars: data.cars,
            trucks: data.trucks,
            motorcycles: data.motorcycles,
            buses: data.buses,
        });
    }

    render() {
        return (
            <div className="py-3 d-flex flex-row justify-content-start">
                <div className="pr-4 daily-count-wrapper">
                    <div className="min-inline-cars mt-1 mb-3">
                        <img height={37} className="mr-2" src={iconCars} alt={'cars icon'}></img>Počet osobných
                        vozidiel: <h6 className="d-inline">{this.state.cars === null ? '-' : this.state.cars}</h6>
                    </div>
                    <div className="min-inline-cars mt-1 mb-1">
                        <img height={37} className="mr-2" src={iconTrucks} alt={'trucks icon'}></img>Počet ostatných
                        vozidiel: <h6 className="d-inline">{this.state.trucks === null ? '-' : this.state.trucks}</h6>
                    </div>
                </div>
                <div className="pl-4">
                    <PieChartCars
                        device={this.props.device}
                        showAlert={this.props.showAlert}
                        requestedDay={this.props.requestedDay}
                    />
                </div>
            </div>
        );
    }
}

DailyCountCars.propTypes = {
    device: PropTypes.object,
    showAlert: PropTypes.func,
    requestedDay: PropTypes.string,
};
