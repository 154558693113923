import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import iconTimelineHandle from '../assets/icons/timeline-nav-handle.svg';

import './TimelineNavigator.scss';

const HOUR_SECONDS = 3600;
const DAY_SECONDS = 86400;
const HALF_YEAR_SECONDS = 15552000; // 180 days seconds

// label pixel sizes for different times
const LABELS_SIZE1 = 38; // timeframe > 180 days
const LABELS_SIZE2 = 38; // 180 days <= timeframe > 24 hours
const LABELS_SIZE3 = 44; // 24 hours <= timeframe

// selector container pixel extend
const LABELS_ADD_WIDTH = 40;

export default class TimelineNavigator extends React.Component {
    constructor(props) {
        super(props);

        this.container = React.createRef();
        this.selector = React.createRef();
        this.prevHandle = React.createRef();
        this.nextHandle = React.createRef();

        this.moveSelector = false;
        this.selectorStartPosition = 0; // Start selector position
        this.selectorHandleOffset = 0; // Offset: handle pos - x pos
        this.selectorPosition = 0; // Current selector position

        this.selectorPosMin = 0;
        this.selectorPosMax = 0;

        this.selectorRightFill = 0; // Added selector width in right side
        this.selectorLeftFill = 0; // Added selector width in left side
        this.minSelectorFill = 0;
        this.maxSelectorFill = 0;

        this.movePrevHandle = false;
        this.moveNextHandle = false;

        // Slider selection in 0..1
        this.start = 0;
        this.end = 0;

        this.currentSegments = 0; // segments count
        this.roundedLengthHours = 0; // current rounded selection in hours (used when only moving)

        this.handleSelectorMouseDown = this.handleSelectorMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMove = this.handleMove.bind(this);
        this.handlePrevHandleDown = this.handlePrevHandleDown.bind(this);
        this.handleNextHandleDown = this.handleNextHandleDown.bind(this);
    }

    componentDidMount() {
        this.selector.current.onmousedown = this.handleSelectorMouseDown;
        window.onmouseup = this.handleMouseUp;
        window.onmousemove = this.handleMove;

        this.prevHandle.current.onmousedown = this.handlePrevHandleDown;
        this.prevHandle.current.onmousemove = this.handlePrevHandleMove;

        this.nextHandle.current.onmousedown = this.handleNextHandleDown;
        this.nextHandle.current.onmousemove = this.handleNextHandleMove;

        this.selectorStartPosition = this.selector.current.getBoundingClientRect().x;
        this.selectorPosition = this.selector.current.getBoundingClientRect().x;

        this.selectorPosMin = this.container.current.getBoundingClientRect().x;
        this.selectorPosMax =
            this.container.current.getBoundingClientRect().x +
            this.container.current.getBoundingClientRect().width -
            this.selector.current.getBoundingClientRect().width;

        this.minSelectorFill = this.selector.current.getBoundingClientRect().width;
        this.maxSelectorFill = this.container.current.getBoundingClientRect().width;

        this.resolveTimeSelection(false);
        this.sendUpdate();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fromUnix !== this.props.fromUnix || prevProps.toUnix !== this.props.toUnix) {
            this.resolveTimeSelection(false, true);
            this.sendUpdate();
        }
    }

    resolveTimeSelection(keepSegments, skipCheck = false) {
        // Check if changed
        const newStart = (this.selectorPosition - this.selectorPosMin - this.selectorLeftFill) / this.maxSelectorFill;
        const newEnd =
            (this.selectorPosition - this.selectorPosMin + this.minSelectorFill + this.selectorRightFill) /
            this.maxSelectorFill;

        if (newStart === this.start && newEnd === this.end && !skipCheck) {
            // No change
            return;
        } else {
            this.start = newStart;
            this.end = newEnd;
        }

        const diff = this.props.toUnix - this.props.fromUnix;

        const momentFrom = moment.unix(this.props.fromUnix + this.start * diff);
        let momentTo = moment.unix(this.props.fromUnix + this.end * diff);

        let possibleToSplit;

        // console.log('=========================');
        // console.log(`%c${momentFrom.toDate().toLocaleString()}`, 'background: #222; color: #bada55');
        // console.log(`%c${momentTo.toDate().toLocaleString()}`, 'background: #222; color: #bada55');

        momentFrom.add(30, 'minutes').startOf('hour');

        if (!keepSegments) {
            momentTo.add(30, 'minutes').startOf('hour');

            possibleToSplit = this.splitSegments(momentFrom, momentTo);
        } else {
            possibleToSplit = this.currentSegments;

            momentTo = momentFrom.clone().add(this.roundedLengthHours, 'hours');
        }

        if (possibleToSplit === -1) {
            // Split not possible, required next rounding

            // Check diff of rounding - which has less that round
            while (possibleToSplit === -1) {
                // Only edit end time
                momentTo.subtract(1, 'hour').startOf('hour');
                possibleToSplit = this.splitSegments(momentFrom, momentTo);

                // Alternative approach, edit start and also end time:

                // if (
                //     Math.abs(originalMomentTo.diff(momentTo, 'minutes')) >=
                //     Math.abs(originalMomentFrom.diff(momentFrom, 'minutes'))
                // ) {
                //     momentTo.subtract(1, 'hour').startOf('hour');
                //     possibleToSplit = this.splitSegments(momentFrom, momentTo);
                // } else {
                //     momentFrom.subtract(1, 'hour').startOf('hour');
                //     possibleToSplit = this.splitSegments(momentFrom, momentTo);
                // }
            }
        }

        if (!keepSegments) {
            this.currentSegments = possibleToSplit;

            // Get hours difference
            this.roundedLengthHours = momentTo.diff(momentFrom, 'hours');
        }

        const segmentSize = (momentTo.unix() - momentFrom.unix()) / HOUR_SECONDS / possibleToSplit;

        // console.log(`%cSegments: ${this.currentSegments}`, 'background: #222; color: #d92317');
        // console.log(`%cRounded length hours: ${this.roundedLengthHours}`, 'background: #222; color: #d92317');
        // console.log(`%c${momentFrom.toDate().toLocaleString()}`, 'background: #222; color: #d92317');
        // console.log(`%c${momentTo.toDate().toLocaleString()}`, 'background: #222; color: #d92317');

        this.props.handleChange(momentFrom, momentTo, possibleToSplit, segmentSize);
    }

    splitSegments(momentFrom, momentTo) {
        const roundedDiff = momentTo.unix() - momentFrom.unix();
        const splitSegments = roundedDiff / HOUR_SECONDS;

        // Check if its possible to split in segment interval
        let possibleToSplit = -1;
        for (let i = this.props.maxSegments; i >= this.props.minSegments; i--) {
            if (splitSegments % i === 0) {
                possibleToSplit = i;
                break;
            }
        }

        return possibleToSplit;
    }

    sendUpdate() {
        const diff = this.props.toUnix - this.props.fromUnix;
        const momentFrom = moment
            .unix(this.props.fromUnix + this.start * diff)
            .add(30, 'minutes')
            .startOf('hour');

        const momentTo = momentFrom.clone().add(this.roundedLengthHours, 'hours');

        this.props.handleUpdate(momentFrom, momentTo, this.currentSegments);
    }

    handleSelectorMouseDown(event) {
        this.moveSelector = true;
        this.selectorHandleOffset = event.clientX - this.selector.current.getBoundingClientRect().x;
    }

    handleMouseUp() {
        if (this.moveSelector || this.movePrevHandle || this.moveNextHandle) {
            if (this.moveSelector) {
                this.moveSelector = false;
            } else if (this.movePrevHandle) {
                this.movePrevHandle = false;
            } else if (this.moveNextHandle) {
                this.moveNextHandle = false;
            }

            this.sendUpdate();
        }
    }

    handleMove(event) {
        if (this.moveSelector) {
            if (event.clientX - this.selectorHandleOffset < this.selectorPosMin) {
                this.selector.current.style.transform = 'translateX(0px)';
                this.prevHandle.current.style.transform = 'translateX(0px)';
                this.nextHandle.current.style.transform = `translateX(${
                    this.selectorRightFill + this.selectorLeftFill
                }px)`;
            } else if (
                event.clientX - this.selectorHandleOffset + this.selectorRightFill + this.selectorLeftFill >
                this.selectorPosMax
            ) {
                const maxPos = this.selectorPosMax - this.selectorStartPosition;
                this.selector.current.style.transform = `translateX(${
                    maxPos - this.selectorRightFill - this.selectorLeftFill
                }px)`;
                this.prevHandle.current.style.transform = `translateX(${
                    maxPos - this.selectorRightFill - this.selectorLeftFill
                }px)`;
                this.nextHandle.current.style.transform = `translateX(${maxPos}px)`;
            } else {
                const currPos = event.clientX - this.selectorStartPosition - this.selectorHandleOffset;
                this.selector.current.style.transform = `translateX(${currPos}px)`;
                this.prevHandle.current.style.transform = `translateX(${currPos}px)`;
                this.nextHandle.current.style.transform = `translateX(${
                    currPos + this.selectorRightFill + this.selectorLeftFill
                }px)`;
            }

            if (event.clientX - this.selectorHandleOffset < this.selectorPosMin) {
                this.selectorPosition = this.selectorPosMin + this.selectorLeftFill;
            } else if (
                event.clientX - this.selectorHandleOffset + this.selectorRightFill + this.selectorLeftFill >
                this.selectorPosMax
            ) {
                this.selectorPosition = this.selectorPosMax - this.selectorRightFill;
            } else {
                this.selectorPosition = event.clientX - this.selectorHandleOffset + this.selectorLeftFill;
            }
            this.resolveTimeSelection(true);
        } else if (this.moveNextHandle) {
            if (event.clientX - this.minSelectorFill + this.selectorLeftFill < this.selectorPosition) {
                this.selectorRightFill = -this.selectorLeftFill;
                this.selector.current.style.width = `${this.minSelectorFill}px`;
                this.nextHandle.current.style.transform = `translateX(${
                    this.selectorPosition - this.selectorPosMin - this.selectorLeftFill
                }px)`;
            } else if (event.clientX - this.minSelectorFill > this.selectorPosMax) {
                this.selectorRightFill =
                    this.maxSelectorFill - this.minSelectorFill - (this.selectorPosition - this.selectorPosMin);
                this.selector.current.style.width = `${
                    this.maxSelectorFill + this.selectorLeftFill - (this.selectorPosition - this.selectorPosMin)
                }px`;
                this.nextHandle.current.style.transform = `translateX(${
                    this.selectorPosMax - this.selectorStartPosition
                }px)`;
            } else {
                this.selectorRightFill = event.clientX - this.selectorPosition - this.minSelectorFill;
                this.selector.current.style.width = `${
                    event.clientX - this.selectorPosition + this.selectorLeftFill
                }px`;
                const currNextHandleX = this.selectorPosition - this.selectorPosMin + this.selectorRightFill;
                this.nextHandle.current.style.transform = `translateX(${currNextHandleX}px)`;
            }
            this.resolveTimeSelection(false);
        } else if (this.movePrevHandle) {
            if (event.clientX > this.selectorPosition + this.selectorRightFill) {
                this.selectorLeftFill = -this.selectorRightFill;
                this.selector.current.style.width = `${this.minSelectorFill}px`;
                this.selector.current.style.transform = `translateX(${
                    this.selectorPosition - this.selectorPosMin - this.selectorLeftFill
                }px)`;
            } else if (event.clientX < this.selectorPosMin) {
                this.selectorLeftFill = this.selectorPosition - this.selectorPosMin;
                this.selector.current.style.transform = `translateX(${
                    this.selectorPosition - this.selectorPosMin - this.selectorLeftFill
                }px)`;
                this.selector.current.style.width = `${
                    this.minSelectorFill + this.selectorRightFill + this.selectorLeftFill
                }px`;
            } else {
                this.selectorLeftFill = this.selectorPosition - event.clientX;
                this.selector.current.style.transform = `translateX(${
                    this.selectorPosition - this.selectorPosMin - this.selectorLeftFill
                }px)`;
                this.selector.current.style.width = `${
                    this.minSelectorFill + this.selectorRightFill + this.selectorLeftFill
                }px`;
            }

            const currPrevHandleX = this.selectorPosition - this.selectorPosMin - this.selectorLeftFill;
            this.prevHandle.current.style.transform = `translateX(${currPrevHandleX}px)`;
            this.resolveTimeSelection(false);
        }
    }

    handlePrevHandleDown() {
        this.movePrevHandle = true;
    }

    handleNextHandleDown() {
        this.moveNextHandle = true;
    }

    renderTimeline() {
        // Compare unix time to - from -> if less 3 for 24h and hour fomrat
        // day format else
        const timeDiff = this.props.toUnix - this.props.fromUnix;

        if (timeDiff > HALF_YEAR_SECONDS) {
            const separatorWidthSize = (this.props.width + LABELS_ADD_WIDTH - 4 * LABELS_SIZE1) / 3;
            const timeFormat = 'DD.MM.[\n]YYYY';

            return (
                <div className={'timeline-label-container mt-3'}>
                    <p className={'size-1'}>{moment.unix(this.props.fromUnix).format(timeFormat)}</p>
                    <p className={'size-1'} style={{ marginLeft: separatorWidthSize }}>
                        {moment.unix(this.props.fromUnix + timeDiff / 3).format(timeFormat)}
                    </p>
                    <p className={'size-1'} style={{ marginLeft: separatorWidthSize }}>
                        {moment.unix(this.props.fromUnix + (timeDiff / 3) * 2).format(timeFormat)}
                    </p>
                    <p className={'size-1'} style={{ marginLeft: separatorWidthSize }}>
                        {moment.unix(this.props.toUnix).format(timeFormat)}
                    </p>
                </div>
            );
        } else if (timeDiff <= HALF_YEAR_SECONDS && timeDiff > DAY_SECONDS) {
            const separatorWidthSize = (this.props.width + LABELS_ADD_WIDTH - 5 * LABELS_SIZE2) / 4;
            const timeFormat = 'DD.MM.[\n]HH:mm';

            return (
                <div className={'timeline-label-container mt-3'}>
                    <p className={'size-2'}>{moment.unix(this.props.fromUnix).format(timeFormat)}</p>
                    <p className={'size-2'} style={{ marginLeft: separatorWidthSize }}>
                        {moment.unix(this.props.fromUnix + timeDiff / 4).format(timeFormat)}
                    </p>
                    <p className={'size-2'} style={{ marginLeft: separatorWidthSize }}>
                        {moment.unix(this.props.fromUnix + (timeDiff / 4) * 2).format(timeFormat)}
                    </p>
                    <p className={'size-2'} style={{ marginLeft: separatorWidthSize }}>
                        {moment.unix(this.props.fromUnix + (timeDiff / 4) * 3).format(timeFormat)}
                    </p>
                    <p className={'size-2'} style={{ marginLeft: separatorWidthSize }}>
                        {moment.unix(this.props.toUnix).format(timeFormat)}
                    </p>
                </div>
            );
        } else {
            // 24h Time frame
            const separatorWidthSize = (this.props.width + LABELS_ADD_WIDTH - 4 * LABELS_SIZE3) / 3;
            const timeFormat = 'DD.MM.[\n]HH:mm';

            return (
                <div className={'timeline-label-container mt-3'}>
                    <p className={'size-3'}>{moment.unix(this.props.fromUnix).format(timeFormat)}</p>
                    <p className={'size-3'} style={{ marginLeft: separatorWidthSize }}>
                        {moment.unix(this.props.fromUnix + timeDiff / 3).format(timeFormat)}
                    </p>
                    <p className={'size-3'} style={{ marginLeft: separatorWidthSize }}>
                        {moment.unix(this.props.fromUnix + (timeDiff / 3) * 2).format(timeFormat)}
                    </p>
                    <p className={'size-3'} style={{ marginLeft: separatorWidthSize }}>
                        {moment.unix(this.props.toUnix).format(timeFormat)}
                    </p>
                </div>
            );
        }
    }

    renderTimeSeparators() {
        const timeDiff = this.props.toUnix - this.props.fromUnix;

        if (timeDiff > HALF_YEAR_SECONDS) {
            return (
                <>
                    <div className={'timeline-separator'} style={{ left: this.props.width / 3 }} />
                    <div className={'timeline-separator'} style={{ left: (this.props.width / 3) * 2 }} />
                </>
            );
        } else if (timeDiff <= HALF_YEAR_SECONDS && timeDiff > DAY_SECONDS) {
            return (
                <>
                    <div className={'timeline-separator'} style={{ left: this.props.width / 4 }} />
                    <div className={'timeline-separator'} style={{ left: (this.props.width / 4) * 2 }} />
                    <div className={'timeline-separator'} style={{ left: (this.props.width / 4) * 3 }} />
                </>
            );
        } else {
            // 24h timeframe
            return (
                <>
                    <div className={'timeline-separator'} style={{ left: this.props.width / 3 }} />
                    <div className={'timeline-separator'} style={{ left: (this.props.width / 3) * 2 }} />
                </>
            );
        }
    }

    render() {
        return (
            <div className={this.props.className}>
                <div className={'timeline-nav-wrapper'} style={{ width: this.props.width + LABELS_ADD_WIDTH }}>
                    <div
                        className={'timeline-nav-container'}
                        ref={this.container}
                        style={{ width: this.props.width, marginLeft: LABELS_ADD_WIDTH / 2 }}
                    >
                        {this.renderTimeSeparators()}
                        <div className={'selector'} ref={this.selector} style={{ width: this.props.width / 10 }} />
                        <img
                            className={'handle-prev'}
                            ref={this.prevHandle}
                            draggable={false}
                            src={iconTimelineHandle}
                            alt={'Timeline navigator previous handle'}
                            style={{ left: '-11px' }}
                        />
                        <img
                            className={'handle-next'}
                            ref={this.nextHandle}
                            draggable={false}
                            src={iconTimelineHandle}
                            alt={'Timeline navigator next handle'}
                            style={{ left: `${-13 + this.props.width / 10}px` }}
                        />
                    </div>
                    {this.renderTimeline()}
                </div>
            </div>
        );
    }
}

TimelineNavigator.propTypes = {
    className: PropTypes.string,
    device: PropTypes.object,
    width: PropTypes.number,
    fromUnix: PropTypes.number,
    toUnix: PropTypes.number,
    minSegments: PropTypes.number,
    maxSegments: PropTypes.number,
    handleChange: PropTypes.func,
    handleUpdate: PropTypes.func,
};
