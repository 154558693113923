import { ApiResponse, JwtDecodeData } from './api.types';
import jwtDecode from 'jwt-decode';
import { Moment } from 'moment';
import download from 'downloadjs';

export default class ApiService {
    public static instance: ApiService;

    private API_BASE_URL: string;
    private PORT: number;

    private readonly JWT_TOKEN_KEY = 'auth_token';
    private readonly USERNAME_KEY = 'username';

    constructor() {
        this.API_BASE_URL = '';
        this.PORT = 0;
    }

    static getInstance(): ApiService {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService();
        }

        return ApiService.instance;
    }

    setBaseUrl(apiBaseUrl: string) {
        this.API_BASE_URL = apiBaseUrl;
    }

    setPort(port: number) {
        this.PORT = port;
    }

    /**
     * Login user, /api/login
     * Loggin spectator here will return error response
     * @param username
     * @param password
     * @returns - response data
     */
    async login(username: string, password: string) {
        const data = new URLSearchParams();
        data.append('username', username);
        data.append('password', password);

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(null);
            } else {
                if (response.status === 401) {
                    return this.constructValidErrorResponse('Invalid username or password');
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getDevicesInfo() {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/devices/get_all`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.devices);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getChart1Data(deviceId: number, datetime: Moment) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('deviceId', JSON.stringify(deviceId));
        data.append('datetime', JSON.stringify(datetime.unix()));

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/data/chart1`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.data);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getChart1CarsData(deviceId: number, datetime: Moment) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('deviceId', JSON.stringify(deviceId));
        data.append('datetime', JSON.stringify(datetime.unix()));

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/data/chart1_cars`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.data);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getPieChartData(deviceId: number, requestedDay: string) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('deviceId', JSON.stringify(deviceId));
        data.append('requestedDay', JSON.stringify(requestedDay));

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/data/pie_chart`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.data);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getPieChartDataCars(deviceId: number, requestedDay: string) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('deviceId', JSON.stringify(deviceId));
        data.append('requestedDay', JSON.stringify(requestedDay));

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/data/pie_chart_cars`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.data);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getChart2Data(
        deviceId: number,
        datetimeFrom: Moment,
        datetimeTo: Moment,
        numOfSegments: number,
        type: string,
    ) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('deviceId', JSON.stringify(deviceId));
        data.append('timeFrom', JSON.stringify(datetimeFrom.unix()));
        data.append('timeTo', JSON.stringify(datetimeTo.unix()));
        data.append('numOfSegments', JSON.stringify(numOfSegments));
        data.append('type', type);

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/data/chart2`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.data);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getChart2CarsData(
        deviceId: number,
        datetimeFrom: Moment,
        datetimeTo: Moment,
        numOfSegments: number,
        type: string,
    ) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('deviceId', JSON.stringify(deviceId));
        data.append('timeFrom', JSON.stringify(datetimeFrom.unix()));
        data.append('timeTo', JSON.stringify(datetimeTo.unix()));
        data.append('numOfSegments', JSON.stringify(numOfSegments));
        data.append('type', type);

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/data/chart2_cars`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.data);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getDeviceDailyData(deviceId: number, requestedDay: string) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('deviceId', JSON.stringify(deviceId));
        data.append('requestedDay', JSON.stringify(requestedDay));

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/data/day_data`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.data);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getDeviceDailyCarsData(deviceId: number, requestedDay: string) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('deviceId', JSON.stringify(deviceId));
        data.append('requestedDay', JSON.stringify(requestedDay));

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/data/day_data_cars`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.data);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getInitialQueryData(
        devices: number[],
        datetimeFrom: Moment,
        datetimeTo: Moment,
        pageSize: number,
        orderBy: object,
    ) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('devices', JSON.stringify(devices));
        data.append('timeFrom', JSON.stringify(datetimeFrom.unix()));
        data.append('timeTo', JSON.stringify(datetimeTo.unix()));
        data.append('pageSize', JSON.stringify(pageSize));
        data.append('orderBy', JSON.stringify(orderBy));

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/query_data/preview`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.data);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getPageQueryData(
        devices: number[],
        datetimeFrom: Moment,
        datetimeTo: Moment,
        page: number,
        pageSize: number,
        orderBy: object,
    ) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('devices', JSON.stringify(devices));
        data.append('timeFrom', JSON.stringify(datetimeFrom.unix()));
        data.append('timeTo', JSON.stringify(datetimeTo.unix()));
        data.append('page', JSON.stringify(page));
        data.append('pageSize', JSON.stringify(pageSize));
        data.append('orderBy', JSON.stringify(orderBy));

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/query_data/preview`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const data = await response.json();

                const tokenData: JwtDecodeData = jwtDecode(data.accessToken);

                if (typeof tokenData.username === 'undefined') {
                    return this.constructUnknownErrorResponse();
                }

                this.storeJWTTokenData(data.accessToken, tokenData.username);
                return this.constructorSucessResponse(data.data);
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    async getQueryExport(devices: number[], datetimeFrom: Moment, datetimeTo: Moment, orderBy: object, format: string,
        interval: string) {
        const token = this.getJWTToken();

        if (token === null) {
            this.logout();
        }

        const data = new URLSearchParams();
        data.append('devices', JSON.stringify(devices));
        data.append('timeFrom', JSON.stringify(datetimeFrom.unix()));
        data.append('timeTo', JSON.stringify(datetimeTo.unix()));
        data.append('orderBy', JSON.stringify(orderBy));
        data.append('format', format);
        data.append('interval', interval);

        let acceptApplication;
        switch (format) {
            case 'csv':
                acceptApplication = 'application/csv';
                break;
            case 'json':
                acceptApplication = 'application/json';
                break;
            case 'xml':
                acceptApplication = 'application/xml';
                break;
            default:
                acceptApplication = 'application/json';
        }

        try {
            const response = await fetch(`${this.API_BASE_URL}:${this.PORT}/api/query_data/export`, {
                method: 'POST',
                headers: {
                    Accept: acceptApplication,
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: data,
            });

            if (response.ok) {
                const blobResponse = await response.blob();
                download(blobResponse, `export.${format}`);

                return;
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.logout();
                } else {
                    return this.constructUnknownErrorResponse();
                }
            }
        } catch (e) {
            return this.constructUnknownErrorResponse();
        }
    }

    storeJWTTokenData(token: string, username: string) {
        localStorage.setItem(this.JWT_TOKEN_KEY, token);
        localStorage.setItem(this.USERNAME_KEY, username);
    }

    logout() {
        this.removeJWTTokenDataData();
        window.location.href = '/';
    }

    hasJWTToken() {
        return localStorage.getItem(this.JWT_TOKEN_KEY) !== null;
    }

    hasUsername() {
        return localStorage.getItem(this.USERNAME_KEY) !== null;
    }

    getJWTToken() {
        const token = localStorage.getItem(this.JWT_TOKEN_KEY);
        if (token === null) {
            this.logout();
        } else {
            return token;
        }
    }

    getUsername() {
        const username = localStorage.getItem(this.USERNAME_KEY);
        if (username === null) {
            this.logout();
        } else {
            return username;
        }
    }

    removeJWTTokenDataData() {
        localStorage.removeItem(this.JWT_TOKEN_KEY);
        localStorage.removeItem(this.USERNAME_KEY);
    }

    constructorSucessResponse(data: any): ApiResponse {
        return {
            success: true,
            data: data,
        };
    }

    constructValidErrorResponse(errorMessage: string): ApiResponse {
        return {
            success: false,
            validError: true,
            errorMessage: errorMessage,
        };
    }

    constructUnknownErrorResponse(): ApiResponse {
        return {
            success: false,
            validError: false,
            errorMessage: 'Error while communicating with server',
        };
    }
}
