import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import './Dropdown.scss';

//In this case, border color must be defined here. Because of react-select default css styling
const style = {
    control: (provided) => ({
        ...provided,
        borderColor: '#dde2ec',
        ':hover': {
            ...provided[':hover'],
            borderColor: '#c9ced7',
        },
    }),

    menu: (provided) => ({
        ...provided,
        zIndex: 2,
    }),
};

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
    }

    renderNameLabel() {
        if (typeof this.props.label !== 'undefined' && this.props.label !== false) {
            return <label className={'input-label'}>{this.props.label}</label>;
        } else {
            return null;
        }
    }

    onSelect(option) {
        this.props.handleChange(option);
    }

    render() {
        return (
            <div
                className={'relative' + (this.props.className ? ' ' + this.props.className : '')}
                style={this.props.style}
            >
                {this.renderNameLabel()}
                <Select
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.onSelect}
                    isDisabled={this.props.isDisabled}
                    isSearchable={this.props.isSearchable}
                    options={this.props.options}
                    className={'select-container'}
                    classNamePrefix={'select'}
                    styles={style}
                />
            </div>
        );
    }
}
Dropdown.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,

    label: PropTypes.string,

    isDisabled: PropTypes.bool,
    isSearchable: PropTypes.bool,

    value: PropTypes.object,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
};
