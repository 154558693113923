import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import DropdownCheckbox from './DropdownCheckbox';
import { Modal } from 'bootstrap';
import ApiService from '../services/ApiService';
import { DataQueryPageControl, OrderDirection } from '../types';

import iconClose from '../assets/icons/x.svg';
import iconChevronMostLeft from '../assets/icons/chevron-most-left.svg';
import iconChevronLeft from '../assets/icons/chevron-left.svg';

import './DataQuery.scss';

const PAGE_SIZE = 30;
const ALL_DEVICES_SYMBOL = '*';

export default class DataQuery extends React.Component {
    constructor(props) {
        super(props);

        this.exportFormats = [
            {
                value: 'csv',
                label: 'csv',
            },
            {
                value: 'json',
                label: 'json',
            },
            {
                value: 'xml',
                label: 'xml',
            },
        ];

        this.exportIntervals = [
            { value: 'hourly', label: 'Hodinový' },
            { value: 'daily', label: 'Denný' },
            { value: 'weekly', label: 'Týždenný' },
            { value: 'monthly', label: 'Mesačný' },
            { value: 'yearly', label: 'Ročný' },
        ];

        // Construct options for devices selection
        this.availableDevices = [];
        for (const device of this.props.devices) {
            this.availableDevices.push({
                value: device.id,
                label: `(${device.id}) ${device.name}`,
            });
        }

        this.state = {
            data: [],
            dataCount: 0,
            dataCountPageFrom: 0,
            dataCountPageTo: 0,
            dataCurrPage: 0,
            dataLoading: false,
            optionsValid: false,
            selectedDevices: [],
            selectedDateFrom: null,
            selectedDateTo: null,
            orderBy: 'createdAt',
            orderDirection: OrderDirection.ASC,
            selectedExportFormat: this.exportFormats[0],
            selectedExportInterval: this.exportIntervals[0],
            exportLoading: false,
        };

        this.exportModalRef = new React.createRef();
        this.queryResultTable = new React.createRef();
        this.widgetContainer = new React.createRef();
        this.modal = null;

        this.api = ApiService.getInstance();

        this.handleSelectDateFrom = this.handleSelectDateFrom.bind(this);
        this.handleSelectDateTo = this.handleSelectDateTo.bind(this);
        this.handleRequestFirstData = this.handleRequestFirstData.bind(this);
        this.handleRequestPageData = this.handleRequestPageData.bind(this);
        this.handleExportData = this.handleExportData.bind(this);
        this.handleSelectExportFormat = this.handleSelectExportFormat.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.modal = new Modal(this.exportModalRef.current);

        const widgetContainerCompStyles = getComputedStyle(this.widgetContainer.current);
        const tableWidth =
            this.widgetContainer.current.clientWidth -
            parseFloat(widgetContainerCompStyles.paddingLeft) -
            parseFloat(widgetContainerCompStyles.paddingRight);
        console.log(tableWidth);

        this.queryResultTable.current.style.width = `${tableWidth}px`;
    }

    openModal() {
        this.modal.show();
    }

    closeModal() {
        this.modal.hide();
    }

    resolveExportOrderText() {
        switch (this.state.orderBy) {
            case 'deviceId':
                return 'ID zariadenia';
            case 'walkersIn':
                return 'počtu prichádzajúcich chodcov';
            case 'walkersOut':
                return 'počtu odchádzajúcich chodcov';
            case 'cyclistsIn':
                return 'počtu prichádzajúcich cyklistov';
            case 'cyclistsOut':
                return 'počtu odchádzajúcich cyklistov';
            case 'carsIn':
                return 'počtu prichádzajúcich osobných vozidiel';
            case 'carsOut':
                return 'počtu odchádzajúcich osobných vozidiel';
            case 'trucksIn':
                return 'počtu prichádzajúcich ostatných vozidiel';
            case 'trucksOut':
                return 'počtu odchádzajúcich ostatných vozidiel';
            case 'createdAt':
                return 'času';
            case 'outdoorTemperature':
                return 'teploty (°C)';
            case 'outdoorFellTemperature':
                return 'pocitovej teploty (°C)';
            case 'windSpeed':
                return 'rýchlosti vetra (m/s)';
            case 'rain':
                return 'zrážok (mm)';
            case 'snow':
                return 'snehu (mm)';
            case 'weatherIconCode':
                return 'piktogramu';
            default:
                return '';
        }
    }

    handleRequestFirstData() {
        this.setState(
            {
                dataLoading: true,
            },
            async () => {
                const devices = [];

                for (const selectedDevice of this.state.selectedDevices) {
                    if (selectedDevice.value !== ALL_DEVICES_SYMBOL) {
                        devices.push(selectedDevice.value);
                    }
                }

                const pageSize = PAGE_SIZE;
                const orderBy = {};
                orderBy[this.state.orderBy] = this.state.orderDirection;

                const response = await this.api.getInitialQueryData(
                    devices,
                    this.state.selectedDateFrom,
                    this.state.selectedDateTo,
                    pageSize,
                    orderBy,
                );

                console.log(response);

                if (response.success) {
                    const responseData = response.data;
                    const currCount = responseData.count > 0 ? 1 : 0;
                    const nextCount = responseData.count > PAGE_SIZE ? PAGE_SIZE : responseData.count;

                    this.setState({
                        data: responseData.data,
                        dataCount: responseData.count,
                        dataCountPageFrom: currCount,
                        dataCountPageTo: nextCount,
                        dataCurrPage: 1,
                        dataLoading: false,
                    });
                } else {
                    this.setState(
                        {
                            dataLoading: false,
                        },
                        () => {
                            this.props.showAlert();
                        },
                    );
                }
            },
        );
    }

    handleRequestPageData(controlPageType) {
        let page;

        switch (controlPageType) {
            case DataQueryPageControl.FIRST:
                page = 1;
                break;
            case DataQueryPageControl.PREV:
                if (this.state.dataCurrPage === 1) {
                    page = 0; // No change
                } else {
                    page = this.state.dataCurrPage - 1;
                }
                break;
            case DataQueryPageControl.CURR:
                page = this.state.dataCurrPage;
                break;
            case DataQueryPageControl.NEXT:
                if (this.state.dataCurrPage === Math.ceil(this.state.dataCount / PAGE_SIZE)) {
                    page = 0; // No change
                } else {
                    page = this.state.dataCurrPage + 1;
                }
                break;
            case DataQueryPageControl.LAST:
                page = Math.ceil(this.state.dataCount / PAGE_SIZE);
                break;
            default:
                page = 0;
                break;
        }

        if (this.state.optionsValid && page > 0) {
            this.setState(
                {
                    dataLoading: true,
                },
                async () => {
                    const devices = [];

                    for (const selectedDevice of this.state.selectedDevices) {
                        if (selectedDevice.value !== ALL_DEVICES_SYMBOL) {
                            devices.push(selectedDevice.value);
                        }
                    }

                    const pageSize = PAGE_SIZE;
                    const orderBy = {};
                    orderBy[this.state.orderBy] = this.state.orderDirection;

                    const response = await this.api.getPageQueryData(
                        devices,
                        this.state.selectedDateFrom,
                        this.state.selectedDateTo,
                        page,
                        pageSize,
                        orderBy,
                    );

                    console.log(response);

                    if (response.success) {
                        const responseData = response.data;
                        const currCount = (responseData.page - 1) * PAGE_SIZE + 1;
                        const nextCount = responseData.page * PAGE_SIZE - (PAGE_SIZE - responseData.data.length);

                        this.setState({
                            data: responseData.data,
                            dataCountPageFrom: currCount,
                            dataCountPageTo: nextCount,
                            dataCurrPage: page,
                            dataLoading: false,
                        });
                    } else {
                        this.setState(
                            {
                                dataLoading: false,
                            },
                            () => {
                                this.props.showAlert();
                            },
                        );
                    }
                },
            );
        }
    }

    handleExportData() {
        this.setState(
            {
                exportLoading: true,
            },
            async () => {
                const devices = [];

                for (const selectedDevice of this.state.selectedDevices) {
                    if (selectedDevice.value !== ALL_DEVICES_SYMBOL) {
                        devices.push(selectedDevice.value);
                    }
                }

                const orderBy = {};
                orderBy[this.state.orderBy] = this.state.orderDirection;

                await this.api.getQueryExport(
                    devices,
                    this.state.selectedDateFrom,
                    this.state.selectedDateTo,
                    orderBy,
                    this.state.selectedExportFormat.value,
                    this.state.selectedExportInterval.value,
                );

                this.setState({
                    exportLoading: false,
                });
            },
        );
    }

    handleDeviceSelection(options) {
        const optionsValid =
            options.length > 0 && this.state.selectedDateFrom !== null && this.state.selectedDateTo !== null;

        this.setState(
            {
                selectedDevices: options,
                optionsValid: optionsValid,
            },
            () => {
                if (optionsValid) {
                    this.handleRequestFirstData();
                }
            },
        );
    }

    handleSelectDateFrom(datetime) {
        // If DatePicker has initial null state, its will be local (not UTC)
        // Solution: add minutes of UTC offset
        // const utcMinuteOffset = moment().utcOffset();

        const optionsValid = this.state.selectedDevices.length > 0 && this.state.selectedDateTo !== null;

        this.setState(
            {
                selectedDateFrom: moment(datetime).startOf('day'),
                optionsValid: optionsValid,
            },
            () => {
                if (optionsValid) {
                    this.handleRequestFirstData();
                }
            },
        );
    }

    handleSelectDateTo(datetime) {
        // If DatePicker has initial null state, its will be local (not UTC)
        // Solution: add minutes of UTC offset
        // const utcMinuteOffset = moment().utcOffset();

        const optionsValid = this.state.selectedDevices.length > 0 && this.state.selectedDateFrom !== null;

        this.setState(
            {
                selectedDateTo: moment(datetime).startOf('day').add(23, 'hours'),
                optionsValid: optionsValid,
            },
            () => {
                if (optionsValid) {
                    this.handleRequestFirstData();
                }
            },
        );
    }

    handleOrderByChange(colName) {
        const changeCallback = () => {
            if (this.state.optionsValid) {
                this.handleRequestPageData(DataQueryPageControl.CURR);
            }
        };

        if (colName === this.state.orderBy) {
            if (this.state.orderDirection === OrderDirection.ASC) {
                this.setState(
                    {
                        orderDirection: OrderDirection.DESC,
                    },
                    changeCallback,
                );
            } else if (this.state.orderDirection === OrderDirection.DESC) {
                this.setState(
                    {
                        orderDirection: OrderDirection.ASC,
                    },
                    changeCallback,
                );
            }
        } else {
            this.setState(
                {
                    orderBy: colName,
                },
                changeCallback,
            );
        }
    }

    handleSelectExportFormat(option) {
        this.setState({
            selectedExportFormat: option,
        });
    }

    handleSelectExportInterval(option) {
        this.setState({
            selectedExportInterval: option,
        });
    }

    getTableHeaderColSymbol(colName) {
        if (colName === this.state.orderBy) {
            if (this.state.orderDirection === OrderDirection.DESC) {
                return '▼';
            } else if (this.state.orderDirection === OrderDirection.ASC) {
                return '▲';
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    renderExportModal() {
        return (
            <div className={'modal fade'} ref={this.exportModalRef} tabIndex={'-1'} role={'dialog'}>
                <div className={'modal-dialog modal-dialog-centered'} role={'document'}>
                    <div className={'modal-content'}>
                        <div className={'modal-header px-4'}>
                            <h5 className={'modal-title'}>Exportovať dáta</h5>
                            <div className={'circle-button'} onClick={this.closeModal}>
                                <img src={iconClose} alt={'Close icon'} />
                            </div>
                        </div>
                        <div className={'modal-body px-4'}>
                            <Dropdown
                                className={'pb-5'}
                                value={this.state.selectedExportFormat}
                                options={this.exportFormats}
                                label={'Formát'}
                                handleChange={(option) => this.handleSelectExportFormat(option)}
                                placeholder={'Výber formátu...'}
                                isSearchable={false}
                            />
                            <Dropdown
                                className={'pb-5'}
                                value={this.state.selectedExportInterval}
                                options={this.exportIntervals}
                                label={'Interval'}
                                handleChange={(option) => this.handleSelectExportInterval(option)}
                                placeholder={'Výber intervalu...'}
                                isSearchable={false}
                            />
                            <div className={'mb-3 pb-2'}>
                                <p className={'d-inline'}>Dáta budú zoradené podľa </p>
                                <p className={'d-inline font-weight-bold'}>
                                    {this.resolveExportOrderText()}
                                    {', '}
                                    {this.state.orderDirection === OrderDirection.DESC ? 'zostupne' : 'vzostupne'}
                                </p>
                            </div>
                            <p className={'description-text'}>
                                Exportovaný čas je vo formáte UTC a zodpovedá lokálnemu času, ktorý je uvedený v
                                tabuľke.
                            </p>
                        </div>
                        <div className={'modal-footer px-4'}>
                            <button className={'secondary-button'} onClick={this.closeModal}>
                                Zrušiť
                            </button>
                            <button
                                disabled={this.state.exportLoading}
                                className={'primary-button'}
                                onClick={this.handleExportData}
                            >
                                {this.state.exportLoading ? 'Exportovanie...' : 'Export'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderEmptyResultContent() {
        return (
            <div
                className={'pt-5 text-center' + (this.state.data.length > 0 ? ' table-status-absolute-container' : '')}
                style={{
                    display:
                        this.state.selectedDevices.length !== 0 &&
                        this.state.selectedDateFrom !== null &&
                        this.state.selectedDateTo !== null
                            ? 'none'
                            : 'block',
                }}
            >
                {this.state.selectedDevices.length === 0 ? <p>Vyberte 1 alebo viac zariadení</p> : null}
                {this.state.selectedDateFrom === null ? <p>Vyberte dátum od</p> : null}
                {this.state.selectedDateTo === null ? <p>Vyberte dátum do</p> : null}
            </div>
        );
    }

    renderDataLoading() {
        return (
            <div
                className={'pt-5 text-center' + (this.state.data.length > 0 ? ' table-status-absolute-container' : '')}
            >
                <p>Načítavam...</p>
            </div>
        );
    }

    renderValidResultContent() {
        const preparingData = !this.state.dataLoading
            ? this.state.optionsValid
                ? null
                : 'data-loading'
            : 'data-loading';
        return (
            <tbody className={preparingData}>
                {this.state.data.map((dataObject, index) => (
                    <tr key={index}>
                        <td className={'pt-3'}>{this.state.dataCountPageFrom + index}</td>
                        <td className={'pt-3'}>{dataObject.deviceId}</td>
                        <td className={'pt-3'}>{moment(dataObject.createdAt).format('DD.MM.YYYY, HH:mm:ss')}</td>
                        <td className={'pt-3'}>{dataObject.walkersIn}</td>
                        <td className={'pt-3'}>{dataObject.walkersOut}</td>
                        <td className={'pt-3'}>{dataObject.cyclistsIn}</td>
                        <td className={'pt-3'}>{dataObject.cyclistsOut}</td>
                        <td className={'pt-3'}>{dataObject.carsIn === null ? '-' : dataObject.carsIn}</td>
                        <td className={'pt-3'}>{dataObject.carsOut === null ? '-' : dataObject.carsOut}</td>
                        <td className={'pt-3'}>{dataObject.trucksIn === null ? '-' : dataObject.trucksIn}</td>
                        <td className={'pt-3'}>{dataObject.trucksOut === null ? '-' : dataObject.trucksOut}</td>
                        <td className={'pt-3'}>{dataObject.outdoorTemperature === null ? '-' : dataObject.outdoorTemperature}</td>
                        <td className={'pt-3'}>{dataObject.outdoorFellTemperature === null ? '-' : dataObject.outdoorFellTemperature}</td>
                        <td className={'pt-3'}>{dataObject.windSpeed === null ? '-' : dataObject.windSpeed}</td>
                        <td className={'pt-3'}>{dataObject.rain === null ? '-' : dataObject.rain}</td>
                        <td className={'pt-3'}>{dataObject.snow === null ? '-' : dataObject.snow}</td>
                        <td className={'pt-3'}>{dataObject.weatherIconCode === null ? '-' : dataObject.weatherIconCode}</td>
                    </tr>
                ))}
            </tbody>
        );
    }

    renderQueryResultPageControl() {
        return (
            <div className={'options-control'}>
                <div
                    className={'mr-1 circle-button'}
                    onClick={() => this.handleRequestPageData(DataQueryPageControl.FIRST)}
                >
                    <img src={iconChevronMostLeft} alt={'Chevron left most icon'} />
                </div>
                <div
                    className={'ml-2 circle-button'}
                    onClick={() => this.handleRequestPageData(DataQueryPageControl.PREV)}
                >
                    <img src={iconChevronLeft} alt={'Chevron left icon'} />
                </div>
                <p className={'px-1 pt-1 data-count-from-to'}>
                    {this.state.dataCountPageFrom} - {this.state.dataCountPageTo}
                </p>
                <div
                    className={'mr-1 circle-button'}
                    onClick={() => this.handleRequestPageData(DataQueryPageControl.NEXT)}
                >
                    <img className={'reversed-icon chevron-right'} src={iconChevronLeft} alt={'Chevron right icon'} />
                </div>
                <div
                    className={'ml-2 circle-button'}
                    onClick={() => this.handleRequestPageData(DataQueryPageControl.LAST)}
                >
                    <img className={'reversed-icon'} src={iconChevronMostLeft} alt={'Chevron right most icon'} />
                </div>
                <p className={'pl-3 pt-1'}>z {this.state.dataCount}</p>
            </div>
        );
    }

    renderQueryResult() {
        return (
            <div className={'query-result-container'}>
                {this.renderQueryResultPageControl()}
                <div className={'query-result-table mt-3'} ref={this.queryResultTable}>
                    <table>
                        <thead>
                            <tr>
                                <th className={'pb-3 px-4'}>#</th>
                                <th
                                    className={'pb-3 px-4 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('deviceId')}
                                >
                                    ID{' '}
                                    <p className={'col-order-by-symbol'}>{this.getTableHeaderColSymbol('deviceId')}</p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('createdAt')}
                                >
                                    Čas{' '}
                                    <p className={'col-order-by-symbol'}>{this.getTableHeaderColSymbol('createdAt')}</p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('walkersIn')}
                                >
                                    Prichádzajúcich chodcov{' '}
                                    <p className={'col-order-by-symbol'}>{this.getTableHeaderColSymbol('walkersIn')}</p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('walkersOut')}
                                >
                                    Odchádzajúcich chodcov{' '}
                                    <p className={'col-order-by-symbol'}>
                                        {this.getTableHeaderColSymbol('walkersOut')}
                                    </p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('cyclistsIn')}
                                >
                                    Prichádzajúcich cyklistov{' '}
                                    <p className={'col-order-by-symbol'}>
                                        {this.getTableHeaderColSymbol('cyclistsIn')}
                                    </p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('cyclistsOut')}
                                >
                                    Odchádzajúcich cyklistov{' '}
                                    <p className={'col-order-by-symbol'}>
                                        {this.getTableHeaderColSymbol('cyclistsOut')}
                                    </p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('carsIn')}
                                >
                                    Prichádzajúcich osobných vozidiel{' '}
                                    <p className={'col-order-by-symbol'}>{this.getTableHeaderColSymbol('carsIn')}</p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('carsOut')}
                                >
                                    Odchádzajúcich osobných vozidiel{' '}
                                    <p className={'col-order-by-symbol'}>{this.getTableHeaderColSymbol('carsOut')}</p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('trucksIn')}
                                >
                                    Prichádzajúcich ostatných vozidiel{' '}
                                    <p className={'col-order-by-symbol'}>{this.getTableHeaderColSymbol('trucksIn')}</p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('trucksOut')}
                                >
                                    Odchádzajúcich ostatných vozidiel{' '}
                                    <p className={'col-order-by-symbol'}>{this.getTableHeaderColSymbol('trucksOut')}</p>
                                </th>

                                {/* Weather conditions */}
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('outdoorTemperature')}
                                >
                                    Teplota{' '}
                                    <p className={'col-order-by-symbol'}>
                                        {this.getTableHeaderColSymbol('outdoorTemperature')}
                                    </p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('outdoorFellTemperature')}
                                >
                                    Pocitová teplota{' '}
                                    <p className={'col-order-by-symbol'}>
                                        {this.getTableHeaderColSymbol('outdoorFellTemperature')}
                                    </p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('windSpeed')}
                                >
                                    Rýchlosť vetra{' '}
                                    <p className={'col-order-by-symbol'}>{this.getTableHeaderColSymbol('windSpeed')}</p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('rain')}
                                >
                                    Zrážky{' '}
                                    <p className={'col-order-by-symbol'}>{this.getTableHeaderColSymbol('rain')}</p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('snow')}
                                >
                                    Sneženie{' '}
                                    <p className={'col-order-by-symbol'}>{this.getTableHeaderColSymbol('snow')}</p>
                                </th>
                                <th
                                    className={'pb-3 px-2 viewer-col-head'}
                                    onClick={() => this.handleOrderByChange('weatherIconCode')}
                                >
                                    Piktogram{' '}
                                    <p className={'col-order-by-symbol'}>
                                        {this.getTableHeaderColSymbol('weatherIconCode')}
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        {this.renderValidResultContent()}
                    </table>
                    {this.state.dataLoading ? this.renderDataLoading() : this.renderEmptyResultContent()}
                </div>
                {this.state.data.length > 0 ? (
                    <div className={'pt-2 mt-4'}>{this.renderQueryResultPageControl()}</div>
                ) : null}
            </div>
        );
    }

    render() {
        return (
            <>
                <div className={'widget-container px-4 py-4 mb-3'} ref={this.widgetContainer}>
                    {this.renderExportModal()}
                    <div className={'data-query-main-container'}>
                        <h4 className={'mt-1 pr-3'}>Export dát</h4>
                        <div className={'data-query-devices-options pt-3'}>
                            <div>
                                <p>Zvolené zariadenia:</p>
                                <DropdownCheckbox
                                    options={this.availableDevices}
                                    isMulti={true}
                                    placeholder={'Vyberte zariadenia...'}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={(options) => this.handleDeviceSelection(options)}
                                    allowSelectAll={true}
                                    value={this.state.selectedDevices}
                                />
                            </div>
                            <div className={'pt-4 data-query-date-options'}>
                                <div className={'mr-4'}>
                                    <p>Dátum od:</p>
                                    <DatePicker
                                        selectedDate={
                                            this.state.selectedDateFrom === null
                                                ? null
                                                : this.state.selectedDateFrom.toDate()
                                        }
                                        startDate={
                                            this.state.selectedDateFrom === null
                                                ? null
                                                : this.state.selectedDateFrom.toDate()
                                        }
                                        endDate={
                                            this.state.selectedDateTo === null
                                                ? null
                                                : this.state.selectedDateTo.toDate()
                                        }
                                        selectsStart={true}
                                        handleChange={this.handleSelectDateFrom}
                                        placeholder={'Vyberte dátum od...'}
                                    />
                                </div>
                                <div className={'ml-3 mr-4'}>
                                    <p>Dátum do:</p>
                                    <DatePicker
                                        selectedDate={
                                            this.state.selectedDateTo === null
                                                ? null
                                                : this.state.selectedDateTo.toDate()
                                        }
                                        startDate={
                                            this.state.selectedDateFrom === null
                                                ? null
                                                : this.state.selectedDateFrom.toDate()
                                        }
                                        endDate={
                                            this.state.selectedDateTo === null
                                                ? null
                                                : this.state.selectedDateTo.toDate()
                                        }
                                        minDate={
                                            this.state.selectedDateFrom === null
                                                ? null
                                                : this.state.selectedDateFrom.toDate()
                                        }
                                        selectsEnd={true}
                                        handleChange={this.handleSelectDateTo}
                                        placeholder={'Vyberte dátum do...'}
                                    />
                                </div>
                                <button
                                    disabled={!this.state.optionsValid}
                                    className={'primary-button options-export-button ml-3'}
                                    onClick={this.openModal}
                                >
                                    Export
                                </button>
                            </div>
                            <div class="mt-4 alert alert-primary" role="alert">
                                    Vysvetlenia jednotlivých piktogramov sú dostupné na:{' '}
                                    <a href="https://openweathermap.org/weather-conditions">
                                        https://openweathermap.org/weather-conditions
                                    </a>
                                </div>
                        </div>
                        {/* <hr /> */}
                    </div>
                </div>
                <div>
                    <div className={'widget-container px-4 py-4 mb-3'}>{this.renderQueryResult()}</div>
                </div>
            </>
        );
    }
}

DataQuery.propTypes = {
    devices: PropTypes.array,
    showAlert: PropTypes.func,
};
